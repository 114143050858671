<template>
    <div class="modal-body step step-8" id="confirmation">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <h2>{{ resx.Thanks }}</h2>
                    <h5>{{ resx.Yourappointment }}</h5>
                    <p style="font-size: 14px;">{{ resx.P8Info }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                            <div class="row bg-light">
                                <div class="col-md-6">
                                    <h5>{{ resx.Confirmation }} #</h5>
                                    <div id="conf-confirmation-number">{{ confNumber }}</div>
                                    <h5>{{ resx.YourInfo }}</h5>
                                    <div id="conf-customer-info">
                                        <div>{{ customerInfo.Name }}</div>
                                        <div>{{ customerInfo.Email }}</div>
                                        <div>{{ customerInfo.Address }}</div>
                                        <div>{{ zipCode }}</div>
                                        <div>{{ customerInfo.Phone }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <h5>{{ resx.YourItems }}</h5>
                                    <ul id="conf-items-list">
                                        <li v-for="item in myItems" :key="item">
                                            <span class="item-name">
                                                {{ item.Count }} &nbsp; {{ item.Name }}
                                            </span>
                                        </li>
                                    </ul>
                                    <h5>{{ resx.Location }}</h5>
                                    <p style="font-size: 14px;" class="jk-location"><i class="fa fa-map-marker-alt"></i>
                                        {{ jkLocation }}
                                    </p>
                                    <h5>
                                        <span>{{ resx.Estimate }}:&nbsp;</span>
                                        <span class="final-price-range">
                                            <span class="jk-red">${{ startPrice }}-${{ endPrice }}</span>
                                            <span class="jk-black d-none d-sm-inline"> (-$20)</span>
                                        </span>
                                    </h5>
                                    <p style="font-size: 14px;">{{ resx.CouponDefaultMessaje }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row button-block mt-1">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <button @click="closeModal" class="btn btn-success btn-block" type="button" data-bs-dismiss="modal">
                       {{ resx.CloseWindow}}
                    </button>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance,inject } from 'vue';

const resx = inject("resx");

const { jkLocation, zipCode, customerInfo,
    myItems, startPrice, endPrice, confNumber } = defineProps({
        jkLocation: String,
        zipCode: String,
        customerInfo: Object,
        myItems: Array,
        startPrice: Number,
        endPrice: Number,
        confNumber: String
    });
const { emit } = getCurrentInstance();
const closeModal = () => {
    emit('closeModal');
}
</script>
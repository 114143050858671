import axios from "axios";
var url = new URL(window.location.href);
const baseUrl = `${url.origin}/API/JunkKingDataApiCore/`;


export function antiForgeryToken() {
    const service = window?.$?.ServicesFramework?.();
    return service?.getAntiForgeryValue() || '';
}

export
    function getConfig(dnnConfig, onSuccess) {
    const url = new URL(window.location.href);
    doFetch(dnnConfig, `${url.origin}/Item/GetConfig`, undefined, undefined, onSuccess);
}
export
    function getResx(dnnConfig, filename, onSuccess) {
    const url = new URL(window.location.href);
    doFetch(dnnConfig,
        `${url.origin}/API/UpendoVentures.Modules.JunkKingPriceEstimator/Resx/GetResx?filename=${filename}`,
        undefined,
        undefined,
        onSuccess);
}
function doFetch(dnnConfig, url, setOptions, data, onSuccess) {
    // default options
    let options = {
        method: 'GET',
        // headers go here
        headers: {
            'Content-Type': 'application/json',
            moduleid: dnnConfig.moduleId,
            tabid: dnnConfig.tabId,
            RequestVerificationToken: antiForgeryToken(),
        },
        body: data ? JSON.stringify(data) : null,
        credentials: 'include'
    }
    if (setOptions) {
        options = { ...options, ...setOptions }
    }
    const req = new Request(url)

    fetch(req, options)
        .then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                return null
            }
        })
        .then((json) => {
            if (typeof (onSuccess) === 'function') {
                onSuccess(typeof (json) === 'string' ? JSON.parse(json) : json)
            }
        })
}


export async function postAsync(endPoint, request, dnnConfig) {
    url = `${baseUrl}${endPoint}`;
    let axiosConfig = {
        method: 'post',
        url: url,
        data: request,
        headers: {
            'Content-Type': 'application/json',
            moduleid: dnnConfig.moduleId,
            tabid: dnnConfig.tabId,
            RequestVerificationToken: dnnConfig.rvt
        },
        withCredentials: true,
    };
    return new Promise(function (resolve, reject) {
        axios({
            ...axiosConfig
        }).then((response) => {
            if (response.status === 200) {
                resolve(response.data);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export async function getAsync(endPoint, dnnConfig) {
    url = `${baseUrl}${endPoint}`;
    let axiosConfig = {
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            moduleid: dnnConfig.moduleId,
            tabid: dnnConfig.tabId,
            RequestVerificationToken: dnnConfig.rvt
        },
        withCredentials: true,
    };
    return new Promise(function (resolve, reject) {
        axios({
            ...axiosConfig
        }).then((response) => {
            if (response.status === 200) {
                resolve(response.data);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export async function readCookie(name, dnnConfig) {
    return new Promise(function (resolve, reject) {
        getAsync(`Site/GetCookie?cookieName=${name}`, dnnConfig).then((response) => {
            if (response.StatusCode === 200) {
                if (response.Result) {
                    resolve(response.Result);
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        }).catch(() => {
            reject(null);
        });
    });
}

export async function writeCookie(name, value, days, dnnConfig) {
    try {
        var response = await postAsync('Site/WriteCookie', { Name: name, Value: value, Days: days }, dnnConfig);
        if (response.StatusCode == 200) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

export function readLocalCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function getPromoCode(pc) {
    if (pc === "seax") {
        var pcode = "";
        var m = new Date().getMonth() + 1;
        if (m === 12 || m <= 2) pcode = "WINTER";
        else if (m >= 3 && m <= 5) pcode = "SPRING";
        else if (m >= 6 && m <= 8) pcode = "SUMMER";
        else pcode = "FALL";
        return pcode;
    } else {
        return pc;
    }
}

export function getDiscount(key) {
    var discounts = {
        "a": "$30", "b": "$35", "c": "$40", "d": "$45", "e": "$50",
        "f": "10%", "g": "15%", "h": "20%", "i": "25%", "j": "30%",
        "k": "$25", "l": "$20"
    };

    return discounts[key] || "$20"; // If the key is not present, it returns "$20" by default
}

export function formatPhoneNumber(phoneNumber) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
}

//log events

function htmlEncode(value) {
    const div = document.createElement('div');
    div.appendChild(document.createTextNode(value));
    return div.innerHTML;
}
export function handleJSError(dnnConfig, ex, fname, details) {
    if (navigator.userAgent.indexOf("MerchantCentricBot") > -1) return;
    var msg = "JavaScript Error:";
    if (document.referrer) msg += "\r\nReferrer: " + document.referrer;
    if (ex.message) msg += "\r\n\r\nMessage: " + ex.message;
    if (ex.fileName) msg += "\r\n\r\nSource: " + ex.fileName;
    if (ex.lineNumber) msg += "\r\nLine No: " + ex.lineNumber;
    if (ex.stack) msg += "\r\nStack:\r\n" + ex.stack;
    if (ex.status) msg += "\r\n\r\nStatus: " + ex.status;
    if (ex.statusText) msg += "\r\nStatus Text: " + ex.statusText;
    if (ex.responseText) msg += "\r\nResponse Text:\r\n" + ex.responseText;
    if (ex.responseXML) msg += "\r\nResponse XML:\r\n" + ex.responseXML;
    if (details) msg += "\r\n\r\nDetails:\r\n" + details;
    logMessage(msg, fname, dnnConfig);
}
function logMessage(msg, fname, dnnConfig) {
    var message = "Location: " + this.location + "\r\n";
    message += "UserAgent: " + navigator.userAgent + "\r\n";
    message += "Function: " + fname + "\r\n";
    message += "\r\n\r\n" + msg;
    postAsync('Logger/LogMessage', { Message: htmlEncode(message) }, dnnConfig);
}

export function logEvent(eventCategory, details, dnnConfig) {
    try {
        var dt = new Date();
        postAsync('Logger/LogEvent', { EventCategory: eventCategory, Details: htmlEncode(details), Url: window.location.href, LocalDT: dt.toString() }, dnnConfig)
            .catch((error) => {
                console.log(error);
                //handleJSError(dnnConfig,error, "LogEvent:error(" + eventCategory + ")");
            });
    } catch (ex) {
        //handleJSError(dnnConfig, ex, "LogEvent(" + eventCategory + ")");
    }
}

export async function isJunkWorks(dnnConfig) {
    return new Promise(function (resolve, reject) {
        const currentUrl = window.location.href;
        postAsync('Site/IsJunkWorksDomain', { Url: currentUrl }, dnnConfig).then((response) => {
            if (response.StatusCode === 200) {
                resolve(response.Result);
            } else {
                resolve(false);
            }
        }).catch(() => {
            reject(false);
        });
    });
}

export function ConversionTracking(tvars, jkcode) {
    try {
        //Google
        if (tvars.gacid != "") {

            //var google_conversion_language = "en";
            //var google_conversion_format = "2";
            //var google_conversion_color = "ffffff";
            //var google_conversion_value = 0;

            var tdiv = document.createElement('div');
            tdiv.setAttribute('style', 'display:inline');
            document.body.appendChild(tdiv);

            var timg = document.createElement('img');
            timg.setAttribute('style', 'height:1px;width:1px;border-style:none;');
            timg.setAttribute('alt', '');
            timg.setAttribute('src', '//www.googleadservices.com/pagead/conversion/' + tvars.gacid + '/?label=' + tvars.gacl + '&amp;guid=ON&amp;script=0');
            tdiv.appendChild(timg);
        }

        var cdiv = document.createElement('div');
        cdiv.setAttribute('style', 'display:inline');
        document.body.appendChild(cdiv);
        var centroIds = [{ location: "Pittsburgh", cid: "ecf016dbb5a6eb55" }, { location: "Chicago Central", cid: "d6a25fc0d96421ad" }, { location: "Chicago Suburbs", cid: "463f25116a0de5cd" }, { location: "Chicago North", cid: "ae71eebfad969d73" }, { location: "Miami North", cid: "9a49a9da468bb8bd" }, { location: "San Carlos", cid: "6a8521176da890dc" }, { location: "Golden Triangle", cid: "8cc7e6b297f891ad" }];
        for (var i = 0; i < centroIds.length; i++) {
            var cimg = document.createElement('img');
            cimg.setAttribute('style', 'height:1px;width:1px;border-style:none;');
            cimg.setAttribute('alt', '');
            cimg.setAttribute('src', '//clickserv.pixel.ad/conv/' + centroIds[i].cid);
            cdiv.appendChild(cimg);
        }

        //var google_conversion_id = 981657787;
        //var google_conversion_language = "en";
        //var google_conversion_format = "3";
        //var google_conversion_color = "ffffff";
        //var google_conversion_label = "eSRCCNme2mQQu9GL1AM";
        //var google_remarketing_only = false;

        var script = document.createElement('script');
        script.src = "//www.googleadservices.com/pagead/conversion.js";
        document.head.appendChild(script);

        //$.getScript("//www.googleadservices.com/pagead/conversion.js");

        AddTrackingPixel("https://www.facebook.com/tr?id=164300653981969&ev=InitiateCheckout&noscript=1");
        AddTrackingPixel("https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp=434733&ec=JKTY");
        AddTrackingPixel("https://px.ads.linkedin.com/collect/?pid=1490170&conversionId=1503290&fmt=gif");
        AddTrackingPixel("https://insight.adsrvr.org/track/pxl/?adv=23gd2s0&ct=0:gvj3g1j&fmt=3");
        //$("body").append('<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=164300653981969&ev=InitiateCheckout&noscript=1" />');
        //$("body").append('<img src="https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp=434733&ec=JKTY"/>');
        //$("body").append('<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1490170&conversionId=1503290&fmt=gif" />');
        //$("body").append('<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=23gd2s0&ct=0:gvj3g1j&fmt=3"/>');

        if (typeof fbq == 'function') { fbq('track', 'Appointment Booked', { "jk": jkcode }); console.log('fbq track executed, jk= ' + jkcode); }
        LoadMountainConversion(jkcode);
        //Brandstar
        //var bsplist = ["19", "160", "403"];
        //if (bsplist.indexOf(spid) > -1) {
        AddTrackingPixel("https://insight.adsrvr.org/track/pxl/?adv=cetef6m&ct=0:b2uxmfz&fmt=3&orderid=" + jkcode);
        //}

        //Microsoft
        //(function (w, d, t, r, u) { var f, n, i; w[u] = w[u] || [], f = function () { var o = { ti: msc }; o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad") }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () { var s = this.readyState; s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null) }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i) })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
        //if (msc != "") {
        //    var parts = msc.split("|");
        //    var siteId = parts[0];
        //    var domainId = parts[1];
        //    var actionId = parts[2];
        //    tdiv = document.createElement('div');
        //    document.body.appendChild(tdiv);
        //    var tiframe = document.createElement('iframe');
        //    tiframe.setAttribute('style', 'visibility:hidden;display:none');
        //    tiframe.setAttribute('frameborder', '0');
        //    tiframe.setAttribute('scrolling', 'no');
        //    tiframe.setAttribute('width', '1');
        //    tiframe.setAttribute('height', '1');
        //    tiframe.setAttribute('src', '//flex.atdmt.com/mstag/tag/' + siteId + '/analytics.html?dedup=1&amp;domainId=' + domainId + '&amp;type=1&amp;actionid=' + actionId);
        //    tdiv.appendChild(tiframe);
        //}
    } catch (ex) {
        //HandleJSError(ex, "conversion-tracking.js");
    }
}
function LoadMountainConversion(jk) {
    //MNTN Conversion Pixel
    var p, q, m,
        o = "32885",
        l = jk,
        i = "N/A",
        c = "",
        k = "",
        g = "",
        j = "",
        u = "",
        shadditional = "";
    try { p = top.document.referer !== "" ? encodeURIComponent(top.document.referrer.substring(0, 512)) : "" } catch (n) { p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : "" } try { q = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location } catch (b) { q = document.location } try { m = parent.location.href !== "" ? encodeURIComponent(parent.location.href.toString().substring(0, 512)) : "" } catch (z) { try { m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : "" } catch (h) { m = "" } } var A, y = document.createElement("script"), w = null, v = document.getElementsByTagName("script"), t = Number(v.length) - 1, r = document.getElementsByTagName("script")[t]; if (typeof A === "undefined") { A = Math.floor(Math.random() * 100000000000000000) } w = "dx.mountain.com/spx?conv=1&shaid=" + o + "&tdr=" + p + "&plh=" + m + "&cb=" + A + "&shoid=" + l + "&shoamt=" + i + "&shocur=" + c + "&shopid=" + k + "&shoq=" + g + "&shoup=" + j + "&shpil=" + u + shadditional; y.type = "text/javascript"; y.src = ("https:" === document.location.protocol ? "https://" : "http://") + w; r.parentNode.insertBefore(y, r);
}

function AddTrackingPixel(url) {
    var el = document.createElement("img");
    el.setAttribute("style", "display:none;height:1px;width:1px;");
    el.setAttribute("src", url);
    document.body.appendChild(el);

}


<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script setup>
</script>
<style>
    .dp__theme_light {
        --dp-cell-size: 25px;
    }
</style>

<template>
    <div class="modal-body step step-2 service-selection" id="bf-service-selection">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-md-12">
                    <h2 class="p-0 m-0">{{ resx.ChooseYourService }}</h2>
                    <h4><span class="jk-location"> <i class="fa fa-map-marker-alt"></i>
                            {{ jkLocation }}</span>&nbsp;(<span class="jk-zip-code">{{ zipCode }}</span>)</h4>
                </div>
            </div>
            <div class="row selection-options">
                <div class="col-sm-6" id="full-service">
                    <div class="panel panel-default" id="panel-full-service">
                        <div class="panel-body bg-light">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="d-md-none hidden-sm">
                                            <h2>{{ resx.FullService }}</h2>
                                            <p style="font-weight: bold;">{{ resx.WeDoAllWork }}</p>
                                        </div><img :src="isJw ? jw_service_full_service : jk_service_full_service"
                                            id="full-service-img" class="img-fluid">
                                    </div>
                                    <div class="col-md-12">
                                        <div class="d-none d-md-block">
                                            <h2>{{ resx.FullService }}</h2>
                                            <p style="font-weight: bold;">{{ resx.WeDoAllWork }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <!-- <div class="row bg-light"> -->
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inner-shadow service-bullets pt-3 pb-3">
                                            <ul style="margin-right:20px;">
                                                <li>{{ resx.S2List1 }}</li>
                                                <li>{{ resx.S2List2 }}</li>
                                                <li>{{ resx.S2List3 }}</li>
                                                <li>1 <span class="bspan">{{ resx.JunkKing }}</span> {{ resx.Truck }} =
                                                    6 {{ resx.S2List4 }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer selection-btn-col">
                            <button class="btn btn-block" id="btn-full-service" type="button" @click="goToStep(3)">
                                {{ resx.ComeGetMyItems }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 pt-2 pt-sm-0" id="self-service">
                    <div class="panel panel-default" id="panel-self-service">
                        <div class="panel-body bg-light">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="d-md-none hidden-sm">
                                            <h2>{{ resx.SelfService }}</h2>
                                            <p style="font-weight: bold;">3-{{ resx.DayDumpsterRental }}</p>
                                        </div><img id="self-service-img" class="img-fluid"
                                            :src="isJw ? jw_service_dumpster : jk_service_dumpster">
                                    </div>
                                    <div class="col-md-12">
                                        <div class="d-none d-md-block">
                                            <h2>{{ resx.SelfService }}</h2>
                                            <p style="font-weight: bold;">3-{{ resx.DayDumpsterRental }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- <div class="row bg-light"> -->
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inner-shadow service-bullets pt-3 pb-3">
                                            <ul>
                                                <li>{{ resx.S2List1 }}</li>
                                                <li>12 {{ resx.S2List5 }}</li>
                                                <li>{{ resx.S2List6 }}</li>
                                                <li>1 <span class="bcspan">JK</span> {{ resx.Dumpster }} = 4
                                                    {{ resx.S2List4 }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer selection-btn-col">
                            <button class="btn btn-block" id="btn-jk-dumpster" type="button" @click="goToStep(7)">
                                {{ resx.ButtonIDoMySelf }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import jk_service_full_service from '@/assets/jk-service-full-service.jpg';
import jk_service_dumpster from '@/assets/jk-service-dumpster.jpg';
import jw_service_full_service from '@/assets/jw-service-full-service.jpg';
import jw_service_dumpster from '@/assets/jw-service-dumpster.jpg';

import { getCurrentInstance, inject } from 'vue';

const resx = inject("resx");

const { jkLocation, zipCode, isJw } = defineProps({
    jkLocation: String,
    zipCode: String,
    isJw: Boolean
});

const { emit } = getCurrentInstance();

const goToStep = (step) => {
    if (step === 7) {
        emit('setIsDumpster', true);
    } else {
        emit('setIsDumpster', false);
    }
    emit('goToStep', step);
}
</script>
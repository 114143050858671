<template>
  <div class="modal" tabindex="-1" id="modalquote" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ resx.CustomQuote }}
          </h5>
          <button type="button" class="btn btn-m-close" @click="close">
            <i class="fa fa-close text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center text-black modal-message1">
            {{ resx.CustomQuoteMessage }}
          </p>
          <p class="text-center text-black modal-link-title modal-message2">
            <img :src="crown"/>
            1-888-888-Junk
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModalQuote" class="modal-backdrop fade show" @click="close"></div>
</template>
<script setup>
import { getCurrentInstance, inject } from 'vue';
import crown from '@/assets/ico-jk-crown.svg';
const { emit } = getCurrentInstance();
const resx = inject("resx");
const close = () => {
  emit('closeQuoteModal');
}
const { showModalQuote } = defineProps({
  showModalQuote: Boolean
});
</script>
<template>
    <div class="modal fade multi-step in" role="dialog" tabindex="-1" id="peModal" style="text-align: center;"
        data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-center">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-1 d-flex justify-content-center">
                                <p>
                                    <button type="button" class="modal-btn-ico" @click="backSteep" v-if="showBackBtn">
                                        <span class="fa fa-angle-left"></span>
                                    </button>
                                </p>
                            </div>
                            <div class="col-10 text-center modal-link-title">
                                <p>
                                    <img :src="isJw ? jw_crown : crown" style="margin-top: -8px; margin-right: 10px;" />
                                    <a href="tel:18888885865" rel="nofollow">(888) 888-JUNK</a>
                                </p>
                            </div>
                            <div class="col-1 text-end">
                                <p>
                                    <button type="button" data-bs-dismiss="modal" class="modal-btn-ico"
                                        @click="closeModal">
                                        <span class="fa fa-close"></span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- steep1 Search zip code-->
                <PageView1 v-if="currentSteep === 1" :crown="isJw ? jw_crown : crown" @setJkLocation="setJkLocation"
                    @setZipCode="setZipCode" @goToStep="goToStep" @setshowSteep2="setshowSteep2" @setPrices="setPrices"
                    @updatePickupTruckData="updatePickupTruckData" @setCategories="setCategories" @setDTACLink="setDTACLink" @setZVResult="setZVResult" :isJw="isJw" />

                <!-- steep2  Select Services-->
                <PageView2 v-if="currentSteep === 2" @goToStep="goToStep" @setIsDumpster="setIsDumpster"
                    :zipCode="zipCode" :jkLocation="jkLocation" :isJw="isJw" />

                <!-- steep10 min full dumster prices-->
                <PageView10 v-if="currentSteep === 10" @goToStep="goToStep" :jkLocation="jkLocation" :zipCode="zipCode"
                    :minPrice="minPrice" :maxPrice="maxPrice" />

                <!-- steep7 Client info Form-->
                <PageView7 v-if="currentSteep === 7" @goToStep="goToStep" :isDumpster="isDumpster"
                    :jkLocation="jkLocation" :zipCode="zipCode" @setCustomerInfo="setCustomerInfo"
                    @setConfNumber="setConfNumber" @setSelectTimes="setSelectTimes" :selectTimes="selectTimes"
                    :customerInfo="customerInfo" @setTimeText="setTimeText" :maxPrice="maxPrice" :myItems="myItems"
                    :startPrice="startPrice" :endPrice="endPrice" :isTruck="isTruck" :truckStr="truckStr"
                    :contactInfo="contactInfo" @setShowBackBtn="setShowBackBtn" :dtacLink="dtacLink" />

                <!-- steep9 Credit Card Form-->
                <PageView9 v-if="currentSteep === 9" :minPrice="minPrice" :confNumber="confNumber"
                    @closeModal="closeModal" @setShowBackBtn="setShowBackBtn" />

                <!-- steep3 By Items or Pickup Truck Loads? -->
                <PageView3 v-if="currentSteep === 3" :jkLocation="jkLocation" :zipCode="zipCode" @setTruck="setTruck"
                    @goToStep="goToStep" />

                <!-- steep5 Save contact -->
                <PageView5 v-if="currentSteep === 5" :zipCode="zipCode" :isTruck="isTruck" @goToStep="goToStep"
                    @setContactInfo="setContactInfo" :contactInfo="contactInfo" />

                <!-- steep 6 pick items -->
                <PageView6 v-if="currentSteep === 6" :zipCode="zipCode" :jkLocation="jkLocation"
                    :categories="categories" :jHPricing="jHPricing" :peRangeLow="peRangeLow" :peRangeHigh="peRangeHigh"
                    @goToStep="goToStep" :pickUpItems="pickUpItems" @updatePickUpItems="updatePickUpItems"
                    :currentCateg="currentCateg" @updateCurrentCateg="updateCurrentCateg"
                    @showModalError="showModalQuote = true" />

                <!-- steep 4 pick Trul -->
                <PageView4 v-if="currentSteep === 4" :zipCode="zipCode" :jkLocation="jkLocation"
                    :peRangeLow="peRangeLow" :peRangeHigh="peRangeHigh" :jHPricing="jHPricing" @goToStep="goToStep"
                    @updatePickupTruckInfo="updatePickupTruckInfo" :fullPickupTruck="fullPickupTruck"
                    :hallfPickupTruck="hallfPickupTruck" @showModalError="showModalQuote = true" />

                <!-- steep 8 finish -->
                <PageView8 v-if="currentSteep === 8" @closeModal="closeModal" :jkLocation="jkLocation"
                    :zipCode="zipCode" :customerInfo="customerInfo" :myItems="myItems" :startPrice="startPrice"
                    :endPrice="endPrice" :confNumber="confNumber" />

                <ModalQuote v-if="showModalQuote" @closeQuoteModal="showModalQuote = false"
                    :showModalQuote="showModalQuote" />
                <ModalCaution v-if="showModalCaution" @close="showModalCaution = false"
                    :showModalCaution="showModalCaution" @leaveSteep5="leaveSteep5" />

                <div class="modal-footer mt-1"></div>
            </div>

        </div>

    </div>
</template>

<script setup>
const showModalCaution = ref(false);
const showModalQuote = ref(false);

import { ref } from 'vue';
import crown from '@/assets/ico-jk-crown.svg';
import jw_crown from '@/assets/ico-jw-crown.svg';

import PageView1 from './PageView1.vue';
import PageView2 from './PageView2.vue';
import PageView10 from './PageView10.vue';
import PageView7 from './PageView7.vue';
import PageView9 from './PageView9.vue';
import PageView3 from './PageView3.vue';
import PageView5 from './PageView5.vue';
import PageView6 from './PageView6.vue';
import PageView4 from './PageView4.vue';
import PageView8 from './PageView8.vue';


import ModalCaution from './ModalCaution.vue';
import ModalQuote from './ModalQuote.vue';

const { isJw } = defineProps({
    isJw: Boolean
});


// Injected dependencies
const minPrice = ref('');
const maxPrice = ref('');

const jkLocation = ref('');
const dtacLink = ref('');
const zipCode = ref('');
const confNumber = ref('');
const selectTimes = ref([]);
const timeText = ref('');
const customerInfo = ref({
    Name: '',
    Address: '',
    Phone: '',
    Email: '',
    Date: '',
    Time: ''
});

const contactInfo = ref({
    Name: '',
    Email: ''
});

//NAVIGATION BEGIN
const showBackBtn = ref(true);
const showSteep2 = ref(false);
const isDumpster = ref(false);
const isTruck = ref(false);
const currentSteep = ref(1);
const previusSteep = ref(1);

const leaveSteep5 = () => {
    showModalCaution.value = false;
    initStimators();
    currentSteep.value = 3;
    previusSteep.value = showSteep2.value ? 2 : 1;

}

const backSteep = () => {
    if (currentSteep.value > 1) {
        if (currentSteep.value === 5) {
            showModalCaution.value = true;
            return;
        }

        if (currentSteep.value === previusSteep.value) {
            if (currentSteep.value === 10) {
                currentSteep.value = 2;
                previusSteep.value = 2;

            } else if (currentSteep.value === 7 && isDumpster.value) {
                currentSteep.value = 10;
                previusSteep.value = 2;
            } else if (currentSteep.value === 7 && isTruck.value) {
                currentSteep.value = 4;
                previusSteep.value = 3;
            }
            else if (currentSteep.value == 3 && !showSteep2.value) {
                currentSteep.value = 1;
                previusSteep.value = 1;
            } else if (currentSteep.value == 4 || currentSteep.value == 6) {
                currentSteep.value = 5;
                previusSteep.value = 3;
            }
            else {
                currentSteep.value = currentSteep.value - 1;
                previusSteep.value = currentSteep.value;
            }
        } else {
            currentSteep.value = previusSteep.value;
        }
    }
}

const goToStep = (step) => {
    previusSteep.value = currentSteep.value;
    currentSteep.value = step;
}

const zvres =  ref({ spid: '', gacid: '', gacl: '', msc: '', countryid: '' });


const resetModal = () => {
    minPrice.value = '';
    maxPrice.value = '';
    jkLocation.value = '';
    dtacLink.value = '';
    zipCode.value = '';
    confNumber.value = '';
    selectTimes.value = [];
    timeText.value = '';
    customerInfo.value = {
        Name: '',
        Address: '',
        Phone: '',
        Email: '',
        Date: '',
        Time: ''
    };
    contactInfo.value = {
        Name: '',
        Email: ''
    };
    showBackBtn.value = true;
    showSteep2.value = false;
    isDumpster.value = false;
    isTruck.value = false;
    currentSteep.value = 1;
    previusSteep.value = 1;
    peRangeLow.value = 0;
    peRangeHigh.value = 0;
    jHPricing.value = [];
    categories.value = [];
    initStimators();

}

const initStimators = () => {
    fullPickupTruck.value = 0;
    hallfPickupTruck.value = 0;
    myItems.value = [];
    startPrice.value = 0;
    endPrice.value = 0;
    truckStr.value = 0;
    pickUpItems.value = [];
    currentCateg.value = 0;
}

const closeModal = () => {
    resetModal();
}
//NAVIGATION END

//Pickup Truck Loads
const peRangeLow = ref(0);
const peRangeHigh = ref(0);
const jHPricing = ref([]);

const updatePickupTruckData = (pricing, rangeLow, rangeHigh) => {
    jHPricing.value = pricing;
    peRangeLow.value = rangeLow;
    peRangeHigh.value = rangeHigh;
}

const fullPickupTruck = ref(0);
const hallfPickupTruck = ref(0);
const myItems = ref([]);
const startPrice = ref(0);
const endPrice = ref(0);
const truckStr = ref('');
const updatePickupTruckInfo = (items, fullCount, halfCount, start, end, truc) => {
    myItems.value = items;
    fullPickupTruck.value = fullCount;
    hallfPickupTruck.value = halfCount;
    startPrice.value = start;
    endPrice.value = end;
    truckStr.value = truc;
}

//end of Pickup Truck Loads

//BEGIN ADDITEMS
const categories = ref([]);
const setCategories = (categ) => {
    categories.value = categ;
}
const pickUpItems = ref([]);
const updatePickUpItems = (items, selected, start, end) => {
    pickUpItems.value = items;
    myItems.value = selected;
    startPrice.value = start;
    endPrice.value = end;
}

const currentCateg = ref(0);
const updateCurrentCateg = (cat) => {
    currentCateg.value = cat;
}

//END ADDITEMS


const setJkLocation = (location) => {
    jkLocation.value = location;
}
const setDTACLink = (link) => {
    dtacLink.value = link;
}
const setPrices = (min, max) => {
    minPrice.value = min;
    maxPrice.value = max;
}

const setZipCode = (zip) => {
    zipCode.value = zip;
}

const setshowSteep2 = (show) => {
    showSteep2.value = show;
}

const setIsDumpster = (isIt) => {
    isDumpster.value = isIt;
}
const setCustomerInfo = (info) => {
    customerInfo.value = info;
}
const setConfNumber = (conf) => {
    confNumber.value = conf;
}
const setSelectTimes = (times) => {
    selectTimes.value = times;
}
const setTimeText = (text) => {
    timeText.value = text;
}
const setShowBackBtn = (show) => {
    showBackBtn.value = show;
}
const setTruck = (isIt) => {
    isTruck.value = isIt;
}
const setContactInfo = (contact) => {
    contactInfo.value = contact;
    customerInfo.value.Name = contactInfo.value.Name,
        customerInfo.value.Email = contactInfo.value.Email
}
const setZVResult = (resp) => {
    zvres.value.countryid = resp.CountryID;
    zvres.value.gacid = resp.GAConversionID;
    zvres.value.gacl = resp.GAConversionLabel;
    zvres.value.msc = resp.MSConversionTracking;
    zvres.value.spid = resp.ServiceProviderId;
}
</script>
<template>
    <div class="modal-body step step-4 book-form" id="bf-booking-form">
        <div class="container-fluid">
            <div class="row header-block pb-3">
                <div class="col-sm-12">
                    <h2 class="online-discount-display p-0 m-0">{{ resx.BookNow }} &amp;&nbsp;<span class="jk-gold">{{
                        resx.Save }}
                            <span class="online-discount-amount jk-gold">{{ jkDiscount }}</span>!*</span></h2>
                </div>
            </div>
            <div class="row bg-light estimate-bar">
                <div class="col-12" style="text-align:center!important;">
                    <h4>
                        <span class="d-none d-md-block jk-location">
                            <i class="fa fa-map-marker-alt"></i>
                            {{ jkLocation }}
                            &nbsp;(<span class="jk-red jk-zip-code">{{ zipCode }}</span>)
                        </span>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4 class="get-the-details">{{ resx.Scheduleyour }} <span class="hd-span">{{ isDumpster ? resx.Dumpster
                        :
                        resx.Estimate }}</span>:</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-6 g-1">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderName"
                        :aria-label="resx.FormPlaceholderName" v-model="formModel.Name" @blur="validateName"
                        :class="showValidates.Name ? (formErrors.Name ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Name">{{ formErrors.Name }}</span>
                </div>
                <div class="col-6 g-1">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderAddress"
                        :aria-label="resx.FormPlaceholderAddress" v-model="formModel.Address" @blur="validateAddress"
                        :class="showValidates.Address ? (formErrors.Address ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Address">{{ formErrors.Address }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6 g-1">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderEmail"
                        :aria-label="resx.FormPlaceholderEmail" v-model="formModel.Email" @blur="validateEmail"
                        :class="showValidates.Email ? (formErrors.Email ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Email">{{ formErrors.Email }}</span>
                </div>
                <div class="col-6 g-1">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderPhone"
                        :aria-label="resx.FormPlaceholderPhone" v-model="formModel.Phone" @blur="validatePhone"
                        :class="showValidates.Phone ? (formErrors.Phone ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Phone">{{ formErrors.Phone }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6 g-1">
                    <VueDatePicker v-model="formModel.Date" week-start="0" auto-apply :min-date="new Date()" :enable-time-picker="false"
                        model-type="MM/dd/yyyy" @closed="getTimes" placeholder="Date"
                        :input-class-name="showValidates.Date ? (formErrors.Date ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Date">{{ formErrors.Date }}</span>
                </div>
                <div class="col-6 g-1">
                    <select class="form-select" aria-label="TIME" v-model="formModel.Time" @blur="validateTime"
                        placeholder="--Select Time--"
                        :class="showValidates.Time ? (formErrors.Time ? 'frm-invalid' : 'frm-valid') : ''">
                        <option v-for="time in times" :key="time.Value" :value="time.Value">{{ time.Text }}</option>
                    </select>
                    <span class="small text-white" v-if="formErrors.Time">{{ formErrors.Time }}</span>
                </div>
                <div id="bf-bag-qt-cont" class="col-sm-6" style="display:none;">
                    <div class="col-sm-6 form-group" style="padding-left:0;">
                        <select class="input-lg disabled" id="bf-bag-qty-book" title="Select # of bags">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>
                    </div>
                    <div class="col-sm-6 form-group" style="padding-right:0;">
                        <input type="text" class="input-lg disabled" id="bf-bag-total" readonly="">
                    </div>
                </div>
                <div class="col-6 g-1">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderCouponCode"
                        :aria-label="resx.FormPlaceholderCouponCode" v-model="jkCouponLocal" />
                </div>
            </div>
            <div class="row button-block">
                <div class="col-sm-3 col-1 d-none d-md-block"></div>
                <div class="col-sm-6">
                    <button class="btn btn-block" id="btn-book-appointment" type="button" @click="goToStep()"
                        :disabled="isLoading">
                        {{ btnSubmitText }}
                    </button>
                </div>
                <div class="col-sm-3 col-1 d-none d-md-block"></div>
            </div>
            <div class="row">
                <div v-if="isDumpster" class="col-md-12 price-notes d-none">
                    <p style="font-size: 14px;" class="mf-msg">** {{ resx.S4Message }}</p>
                </div>

                <p class="sms-dis">{{ resx.S4Message1 }}</p>
            </div>
        </div>
    </div>
</template> 
<script setup>
import { getCurrentInstance, ref, inject, watch } from 'vue';
import { postAsync, readCookie, logEvent, readLocalCookie, ConversionTracking } from "@/assets/api";
import VueDatePicker from '@vuepic/vue-datepicker';

// Injected dependencies
const resx = inject("resx");
const dnnConfig = inject("dnnConfig");

const { jkLocation, zipCode, isDumpster, jkCoupon, customerInfo, selectTimes, jkDiscount, zvres } = defineProps({
    jkLocation: String,
    zipCode: String,
    isDumpster: Boolean,
    jkCoupon: String,
    customerInfo: Object,
    selectTimes: Array,
    jkDiscount: String,
    zvres: Object
});

const btnSubmitText = ref('');

const { emit } = getCurrentInstance();

const updateTimeText = () => {
    if (formModel.value.Time != '') {
        var time = times.value.find(x => x.Value == formModel.value.Time);
        emit('setTimeText', time.Text);
    }
}

const saveAppointment = async () => {
    isLoading.value = true;
    initShowValidate();
    //call saveapoiment api
    var nparts = formModel.value.Name.split(' ');
    var fname = nparts[0].trim();
    var lname = nparts.length > 2 ? nparts.slice(1).join(' ') : nparts[1];
    var addinfo = '';
    try {
            var alCookieVal = readLocalCookie("_adl_id.AL_823");
            if (alCookieVal != null) addinfo += (addinfo == "" ? "" : ";") + "alsid:" + alCookieVal;
        } catch (ex) { 
            //HandleJSError(ex, "SaveAppointment-ReadCookie");
        }


    if (jkCouponLocal.value != '') {
        addinfo += (addinfo == "" ? "" : ";") + "pcode:" + jkCouponLocal.value;
    }

    var landingPage = '';
    var referringPage = '';

    try {
        var piCookie = await readCookie("page-info",dnnConfig);
        if (piCookie != null) {
            var vobj = JSON.parse(piCookie);
            referringPage = vobj.r;
            landingPage = vobj.lp;
        }
    } catch (ex) {
        //handleJSError(dnnConfig, ex, "SaveAppointment-ReadCookie");
    }
    var request = {
        Fname: fname,
        Lname: lname,
        Phone: formModel.value.Phone,
        Email: formModel.value.Email,
        Address: formModel.value.Address,
        Zip: zipCode,
        Date: formModel.value.Date,
        Time: formModel.value.Time,
        ItemList: '',
        Notes: '',
        HowHeard: '',
        HowHeardOther: '',
        AppointmentTypeId: '1',
        UserAgent: navigator.userAgent,
        BoUrl: window.location.href,
        LandingPage: landingPage,
        ReferringPage: referringPage,
        AdditionalInfo: addinfo,
        IsDumpster: isDumpster
    };
    logEvent("BookOnline: SaveAppointment-Start", request.Email + '|' + request.Zip + '|' + request.Date + '|' + request.Time, dnnConfig);
    postAsync('BookOnlineExpress/SaveAppointment', request, dnnConfig)
        .then(response => {
            emit('setConfNumber', response.Result.JKNumber);
            emit('setCustomerInfo', formModel.value);
            emit('setSelectTimes', times.value);
            ConversionTracking(zvres, response.Result.JKNumber);
            updateTimeText();
            logEvent("BookOnline: SaveAppointment-Success", "JK #: " + response.Result.JKNumber, dnnConfig);
            isLoading.value = false;
            var step = 6;
            if (isDumpster) {
                step = 5;
            }
            emit('goToStep', step);
        })
        .catch(() => {
            isLoading.value = false;
            alert(resx.JunkSaverAppointmentError);
            //handleJSError(dnnConfig,ex, "SaveAppointment");
        })
}

const getCouponDetails = async () => {
    try {
        var response = await postAsync('BookOnlineExpress/GetCouponDetails', { Ccode: jkCouponLocal.value, Zip: zipCode }, dnnConfig);
        if (response.Result != null && response.Result != '') {
            emit('setJkCouponMessage', response.Result);
            emit('setJkCoupon', jkCouponLocal.value);
        } else {
            emit('setJkCouponMessage', resx.CouponDefaultMessaje);
            emit('setJkCoupon', '');
        }
    } catch {
        alert(resx.ServerError);
    }
}

const goToStep = async () => {
    if (validateForm()) {
        if (jkCouponLocal.value != '') {
            await getCouponDetails();
        }
        else {
            emit('setJkCouponMessage', resx.CouponDefaultMessaje);
            emit('setJkCoupon', '');
        }
        await saveAppointment();
    }
}

//form
const isLoading = ref(false);
const jkCouponLocal = ref(jkCoupon);
const formModel = ref(customerInfo);
const formErrors = ref({
    Name: '',
    Address: '',
    Phone: '',
    Email: '',
    Date: '',
    Time: '',
});
const showValidates = ref({
    Name: false,
    Address: false,
    Phone: false,
    Email: false,
    Date: false,
    Time: false,
});
const times = ref(selectTimes);

const initShowValidate = () => {
    showValidates.value = {
        Name: false,
        Address: false,
        Phone: false,
        Email: false,
        Date: false,
        Time: false,
    }
}

//validations
const validateDate = () => {
    showValidates.value.Date = true;
    if (formModel.value.Date == null || formModel.value.Date == "") {
        formErrors.value.Date = resx.ValDate;
    } else {
        formErrors.value.Date = "";
    }
}

const getTimes = async () => {
    validateDate();
    if (formModel.value.Date != null && formModel.value.Date != "") {
        var request = {
            Date: formModel.value.Date,
            Zip: zipCode,
            IsDumpster: isDumpster
        }
        postAsync("BookOnlineExpress/GetAvailableTimes", request, dnnConfig)
            .then(response => {
                times.value = response.Result.Option;
                logEvent("BookOnline: GetAvailableTimesSuccess",  JSON.stringify({ results: response.Result.Option.filter(option => option.Value !== null).map(option => option.Value).join(', '), date: formModel.value.Date, zip: zipCode }), dnnConfig);
            })
            .catch(() => {
                alert(resx.ServerError);
            })
    } else {
        times.value = [];
        formModel.value.Date = '';
        formErrors.value.Date = resx.ValDate;
        alert(resx.JunkCallMessage);
    }

}
const validateName = () => {
    showValidates.value.Name = true;
    const nameRegex = /^[A-Za-z]+ [A-Za-z]+$/;
    if (!nameRegex.test(formModel.value.Name)) {
        formErrors.value.Name = resx.ValName;
    } else {
        formErrors.value.Name = '';
    }
};

function validatePhone() {
    showValidates.value.Phone = true;
    formModel.value.Phone = formModel.value.Phone.trim();
    if (formModel.value.Phone == 'PHONE' || formModel.value.Phone == '') {
        formErrors.value.Phone = resx.ValPhoneReq;
    } else if (!formModel.value.Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        formErrors.value.Phone = resx.ValPhoneInvalid;
    }
    else {
        formErrors.value.Phone = '';
    }
}

function validateEmail() {
    showValidates.value.Email = true;
    formModel.value.Email = formModel.value.Email.trim();
    if (formModel.value.Email == 'EMAIL' || formModel.value.Email == '') {
        formErrors.value.Email = resx.ValEmailReq;
    } else if (!formModel.value.Email.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)) {
        formErrors.value.Email = resx.ValEmailInvalid;
    } else {
        formErrors.value.Email = '';
    }
}

let validateAddress = () => {
    showValidates.value.Address = true;
    formModel.value.Address = formModel.value.Address.trim();
    if (formModel.value.Address == 'ADDRESS' || formModel.value.Address == '') {
        formErrors.value.Address = resx.ValAddressReq;
    } else if (formModel.value.Address.split(' ').length < 2) {
        formErrors.value.Address = resx.ValAddressInvalid;
    } else {
        formErrors.value.Address = '';
    }
}

let validateTime = () => {
    showValidates.value.Time = true;
    if (formModel.value.Time == '') {
        formErrors.value.Time = resx.ValTimeReq;
    } else {
        formErrors.value.Time = '';
    }
}

//format phone
let formatPhoneNumber = (value) => {
    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
}


let validateForm = () => {
    validateName();
    validateAddress();
    validatePhone();
    validateEmail();
    validateDate();
    validateTime();

    let valid = true;
    for (const key in formErrors.value) {
        if (formErrors.value[key] != '') {
            valid = false;
            break;
        }
    }
    return valid;
}
watch(formModel, () => {
    formModel.value.Phone = formatPhoneNumber(formModel.value.Phone);
}, { deep: true });

btnSubmitText.value = isDumpster ? resx.Next : resx.BookIt;
</script>
<template>
    <div class="modal-body step step-6" id="bf-confirmation">
        <div class="container-fluid">
            <div class="row">
                <div v-if="!isDumpster" id="hauling-thank-you" class="col-md-12">
                    <h3>{{ resx.EstimateThanks }}</h3>
                    <p style="font-size: 16px;">{{ resx.EstimateMessage1 }} <br> {{ resx.EstimateMessage2 }}</p>
                </div>
                <div v-if="isDumpster" id="dumpster-thank-you" class="col-md-12">
                    <h3>{{ resx.DumpsterThanks }}</h3>
                    <p style="font-size: 16px;">{{ resx.DumpsterMessage }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                            <div class="row bg-light">
                                <div class="col-sm-6 col-12">
                                    <h5>{{resx.Confirmation}} #</h5>
                                    <div id="conf-confirmation-number">
                                        <p style="font-size: 16px;">{{ confNumber }}</p>
                                    </div>
                                    <h5>{{resx.Appointment}}</h5>
                                    <p style="font-size: 16px;" class="jk-location">
                                        <i class="fa fa-map-marker-alt"></i> 
                                        {{ jkLocation }} <br>
                                        {{ customerInfo.Date }} @ {{ timeText }}
                                    </p>
                                    <p style="font-size: 16px;" id="bf-confirmation-dt">{{ dateTime }}</p>
                                    <h5>{{ resx.CouponCode }}</h5>
                                    <p style="font-size: 16px;" id="bf-confirmation-coupon">
                                        <span v-if="jkCoupon!=''">{{ jkCoupon }}</span> <br v-if="jkCoupon!=''">
                                        {{ jkCouponMessage }}
                                    </p>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <h5>{{ resx.YourItems }}</h5>
                                    <p style="font-size: 16px;" id="bf-confirmation-items">{{ customerItems.join(', ') }}
                                    </p>
                                    <h5>{{ resx.YourInfo }}</h5>
                                    <div id="conf-customer-info">
                                        <p style="font-size: 16px;">
                                            {{ customerInfo.Name }} <br>
                                            {{ customerInfo.Email }} <br>
                                            {{ customerInfo.Address }} <br>
                                            {{ zipCode }} <br>
                                            {{ customerInfo.Phone }} 
                                        </p>
                                    </div>
                                    <div id="bf-confirmation-comments-cont" v-if="!isDumpster">
                                        <h5>{{ resx.Comment }}</h5>
                                        <p style="font-size: 16px;" id="bf-confirmation-comments">{{ bfComments }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row button-block">
                <div class="col-sm-3 col-1 d-none d-md-block"></div>
                <div class="col-sm-6">
                    <button class="btn btn-block" type="button"  data-bs-dismiss="modal" @click="closeModal()">
                        {{ resx.CloseWindow }}
                    </button>
                </div>
                <div class="col-sm-3 col-1 d-none d-md-block"></div>
            </div>
        </div>
</div>
</template>
<script setup>
import { getCurrentInstance, inject } from 'vue';

const resx = inject("resx");

const { jkLocation, confNumber, dateTime,jkCoupon,jkCouponMessage, customerItems, customerInfo, bfComments, isDumpster, zipCode, timeText } = defineProps({
    jkLocation: String,
    confNumber: String,
    dateTime: String,
    jkCoupon: String,
    jkCouponMessage: String,
    customerItems: Array,
    customerInfo:Array,
    bfComments:String,
    isDumpster: Boolean,
    zipCode: String,
    timeText: String
});

const { emit } = getCurrentInstance();

const closeModal = () => {
    emit('closeModal');
}
</script>
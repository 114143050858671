<template>
    <div class="modal-body step step-5 book-form" id="bf-service-dumpster-rental-credit">
        <div class="container-fluid">
            <div class="row header-block pb-3">
                <div class="col-sm-12">
                    <h2 class="online-discount-display p-0 m-0">{{ resx.BookNow }} &amp;&nbsp;<span class="jk-gold">{{
                        resx.Save }}
                            <span class="online-discount-amount jk-gold">{{ jkDiscount }}</span>!</span></h2>
                </div>
            </div>
            <div class="row bg-light estimate-bar">
                <div class="col-12" style="text-align:center!important;">
                    <h4><span class="d-none d-md-block jk-location">
                            <i class="fa fa-map-marker-alt"></i>
                            {{ jkLocation }}
                            &nbsp;(<span class="jk-red jk-zip-code">{{ zipCode }}</span>)
                        </span>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4 class="get-the-details">{{ resx.CreditCardFormTitle }}</h4>
                    <p style="font-size: 16px;">
                        <span>{{ resx.S5Message1 }} ${{ minPrice }}, {{ resx.S5Message2 }}</span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 g-1">
                    <input type="text" class="form-control" id="tCard" v-model="formModel.Ccardno" @blur="validateCcardno"
                        :class="showValidates.Ccardno ? (formErrors.Ccardno ? 'frm-invalid' : 'frm-valid') : ''"
                        :placeholder="resx.CardNumber" />
                    <span class="small text-white" v-if="formErrors.Ccardno">{{ formErrors.Ccardno }}</span>
                </div>
                <div class="col-6 g-1">
                    <input type="text" maxlength="5" :placeholder="resx.ExpirationDate" autocomplete="off"
                        class="form-control" id="tExpire" v-model="formModel.Expires" @blur="validateExpires"
                        :class="showValidates.Expires ? (formErrors.Expires ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Expires">{{ formErrors.Expires }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6 g-1">
                    <input type="text" :placeholder="resx.NameonCard" class="form-control" id="tName"
                        v-model="formModel.Noc" @blur="validateNoc"
                        :class="showValidates.Noc ? (formErrors.Noc ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Noc">{{ formErrors.Noc }}</span>
                </div>
                <div class="col-6 g-1">
                    <input type="text" :placeholder="resx.BillingZipCode" class="form-control" id="tZip"
                        v-model="formModel.Bzip" @blur="validateBzip"
                        :class="showValidates.Bzip ? (formErrors.Bzip ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Bzip">{{ formErrors.Bzip }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-md-12 g-1 d-flex justify-content-center">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="bf-accept-tac-cb"
                               v-model="acepTerms">
                        <label class="form-check-label" for="bf-accept-tac-cb">
                            &nbsp;{{ resx.IAccept }}
                        </label>
                        &nbsp;<a class="dtac-link"
                                 :href="dtacLink" target="_blank"
                                 style="text-decoration: underline;">
                            {{ resx.Terms }}
                        </a>.
                    </div>
                </div>
            </div>
            <div class="row button-block">
                <div class="col-sm-3 col-1 d-none d-md-block"></div>
                <div class="col-sm-6">
                    <button @click="goToStep(6)" class="btn btn-block" id="btn-dumpster-book-appointment" type="button"
                        :disabled="isLoading">
                        {{ resx.ButtonBookIt }}
                    </button>
                </div>
                <div class="col-sm-3 col-1 d-none d-md-block"></div>
            </div>
            <div class="row">
                <div class="col-md-12 price-notes">
                    <p style="font-size: 14px;" class="mf-msg">** {{ resx.S4Message }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, inject } from 'vue';
import { postAsync } from "@/assets/api";

const resx = inject("resx");
const dnnConfig = inject("dnnConfig");

const { jkLocation, zipCode, minPrice, acepTermsandConditions, cardInfo, confNumber, jkDiscount, dtacLink } = defineProps({
    jkLocation: String,
    zipCode: String,
    minPrice: Number,
    acepTermsandConditions: Boolean,
    cardInfo: Object,
    confNumber: String,
    jkDiscount:String,
    dtacLink: String
});

const formModel = ref(cardInfo);
const formErrors = ref({
    Ccardno: '',
    Expires: '',
    Bzip: '',
    Noc: ''
});
const showValidates = ref({
    Ccardno: false,
    Expires: false,
    Bzip: false,
    Noc: false
});

const isLoading = ref(false);

const acepTerms = ref(acepTermsandConditions);

const { emit } = getCurrentInstance();

const goToStep = async (step) => {
    var isValid = validateForm();
    if (isValid) {
        if (!acepTerms.value) {
            alert(resx.PleaseAcceptTerms);
            console.log('here');
            console.log('dtacLink=' + dtacLink);
            return;
        }
        isLoading.value = true;

        initShowValidate();
        var match = formModel.value.Ccardno.match(/\d{4}$/g);
        var lastFour = match ? match[0] : '';
        var request = {
            Jkno: confNumber,
            Ccardno: formModel.value.Ccardno,
            Expires: formModel.value.Expires,
            Bzip: formModel.value.Bzip,
            Noc: formModel.value.Noc,
            Cvn: '',
            LastFour: lastFour
        };

        postAsync('BookOnline/SaveCreditCard', request, dnnConfig)
            .then(response => {
                if (response.StatusCode == 200) {
                    isLoading.value = false;
                    emit('updateCardInfo', formModel.value, acepTerms.value);
                    emit('goToStep', step);
                }

            })
            .catch(() => {
                isLoading.value = false;
                alert(resx.ServerError);
            });
    }
}

//validations
function initShowValidate() {
    showValidates.value = {
        Ccardno: false,
        Expires: false,
        Bzip: false,
        Noc: false
    };
}
let validateForm = () => {
    validateCcardno();
    validateExpires();
    validateBzip();
    validateNoc();

    let valid = true;
    for (const key in formErrors.value) {
        if (formErrors.value[key] != '') {
            valid = false;
            break;
        }
    }
    return valid;
}

const validateCreditCardNumber = (number) => {
    let reversedDigits = number.split('').reverse().map(Number);
    let sum = 0;

    for (let i = 0; i < reversedDigits.length; i++) {
        let digit = reversedDigits[i];

        if (i % 2 === 1) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
    }
    return sum % 10 === 0;
};

const validateCcardno = () => {
    showValidates.value.Ccardno = true;
    if (formModel.value.Ccardno.length === 0) {
        formErrors.value.Ccardno = resx.ValCreditCardRequired;
    } else if (!validateCreditCardNumber(formModel.value.Ccardno)) {
        formErrors.value.Ccardno = resx.ValCreditCardInvalid;

    } else {
        formErrors.value.Ccardno = '';
    }
}

const validateExpires = () => {
    showValidates.value.Expires = true;
    formModel.value.Expires = formModel.value.Expires.trim();
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;

    if (formModel.value.Expires === '' || !regex.test(formModel.value.Expires)) {
        formErrors.value.Expires = resx.ValCardExpiresReq;
    } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear().toString().slice(-2);
        const currentMonth = currentDate.getMonth() + 1;

        const [expirationMonth, expirationYear] = formModel.value.Expires.split('/').map(Number);
        if (expirationYear < currentYear || (expirationYear === currentYear && expirationMonth < currentMonth)) {
            formErrors.value.Expires = resx.ValCardExpired;
        } else {
            formErrors.value.Expires = '';
        }
    }
}

const validateBzip = () => {
    showValidates.value.Bzip = true;
    formModel.value.Bzip = formModel.value.Bzip.trim();
    if (formModel.value.Bzip == '') {
        formErrors.value.Bzip = resx.ValBZipReq;
    } else {
        formErrors.value.Bzip = '';
    }
}

const validateNoc = () => {
    showValidates.value.Noc = true;
    formModel.value.Noc = formModel.value.Noc.trim();
    if (formModel.value.Noc == '') {
        formErrors.value.Noc = resx.ValNameOnCardReq;
    } else {
        formErrors.value.Noc = '';
    }
}
</script>
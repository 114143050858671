<template>
    <div class="modal-body step step-3 service-selection" id="service-selection-items-truckloads">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-md-12">
                    <h2 class="p-0 m-0">{{ resx.P3Title }}</h2>
                    <h4><span class="jk-location"><i class="fa fa-map-marker-alt"></i> {{ jkLocation
                    }}</span><span>&nbsp;(</span><span class="jk-zip-code">{{ zipCode }}</span><span>)</span></h4>
                </div>
            </div>
            <div class="row selection-options">
                <div class="col-sm-6 service-column">
                    <div class="panel panel-default p-xs-3" id="panel-add-items">
                        <div class="panel-body bg-light">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h2 class="d-block d-sm-none jk-red">{{ resx.AddMyItems }}</h2>
                                        <div style="background-color:#f9f9f9;"><img class="img-fluid"
                                                :src="service_add_items" style="margin:0 auto;"></div>
                                        <h4 class="d-none d-sm-block jk-red">{{ resx.AddMyItems }}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inner-shadow service-bullets">
                                            <p><strong>{{ resx.Recommendedfor }}</strong></p>
                                            <ul>
                                                <li>{{ resx.FurnitureRemoval }}</li>
                                                <li>{{ resx.ApplianceDisposalRecycling }}</li>
                                                <li>{{ resx.ElectronicsDisposalRecycling }}</li>
                                                <li>{{ resx.MattressDisposal }}</li>
                                                <li>{{ resx.HotTubDisposal }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer">
                            <button class="btn btn-success btn-block" id="btn-fs-add-items" type="button"
                                @click="goToStep(false)">
                                {{ resx.AddMyItems }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 service-column">
                    <div class="panel panel-default p-xs-3" id="panel-pickup-truckloads">
                        <div class="panel-body bg-light">
                            <div>
                                <div class="row ">
                                    <div class="col-md-12">
                                        <h2 class="d-block d-sm-none jk-red">{{ resx.ByPickupTruckLoads }}</h2>
                                        <div style="background-color:#f9f9f9;"><img class="img-fluid"
                                                :src="service_truckloads" style="margin:0 auto"></div>
                                        <h4 class="d-none d-sm-block jk-red">{{ resx.ByPickupTruckLoads }}</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inner-shadow service-bullets">
                                            <p><strong>{{ resx.Recommendedfor }}:</strong></p>
                                            <ul>
                                                <li>{{ resx.YardWasteRemoval }}</li>
                                                <li>{{ resx.ConstructionWasteRemoval }}</li>
                                                <li>{{ resx.ForeclosureCleanOuts }}</li>
                                                <li>{{ resx.TrashGarbageRemoval }}</li>
                                                <li>{{ resx.GarageCleanOuts }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer"><button class="btn btn-success btn-block" id="btn-fs-truckloads"
                                type="button" @click="goToStep(true)">
                                {{ resx.ByPickupTruckLoads }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance,inject } from 'vue';
import service_add_items from '@/assets/service_add_items.png';
import service_truckloads from '@/assets/service_truckloads.png';

const resx = inject("resx");

const { jkLocation, zipCode } = defineProps({
    jkLocation: String,
    zipCode: String
});
const { emit } = getCurrentInstance();

const goToStep = (truck) => {
    emit("setTruck", truck)
    emit("goToStep", 5);
}
</script>
<template>
    <div class="modal-body step step-3" id="bf-service-truck-hauling">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h2 class="p-0 m-0 select-items-heading" id="category-heading">{{ resx.S3Title }}</h2>
                </div>
            </div>
            <div class="row subheader-block">
                <div class="col-lg-6 col-sm-6 col-12">
                    <p class="m-0"><span style="font-weight: bold; font-size: 0.6em;">{{ resx.SelectCategories }}</span></p>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <h5 class="mt-0"><span class="jk-location"> <i class="fa fa-map-marker-alt"></i>
                            {{ jkLocation }}</span></h5>
                </div>
            </div>
            <div class="row" id="row-item-category">
                <div class="col-lg-4 col-6 household category">
                    <a href="#" id="add-item-cat-household" data-category="household"
                        :class="{ 'active': itemsActive.household }" @click="activeItem(0)">
                        <img :src="jk_booking_form_items_01_household_items_01a">
                    </a>
                    <div>
                        <span>{{ resx.HouseholdItems }}</span>
                    </div>
                </div>
                <div class="col-lg-4 col-6 furniture category">
                    <a href="#" id="add-item-cat-furniture" data-category="furniture"
                        :class="{ 'active': itemsActive.furniture }" @click="activeItem(1)">
                        <img :src="jk_booking_form_items_02_furniture_01a">
                    </a>
                    <div>
                        <span>{{ resx.Furniture }}</span>
                    </div>
                </div>
                <div class="col-lg-4 col-6 yard-debris category">
                    <a href="#" id="add-item-cat-yard-debris" data-category="yard-debris"
                        :class="{ 'active': itemsActive.yardDebris }" @click="activeItem(2)">
                        <img :src="yardDebrisImage">
                    </a>
                    <div><span>{{ resx.YardDebris }}</span></div>
                </div>
                <div class="col-lg-4 col-6 appliances category">
                    <a href="#" id="add-item-cat-appliances" data-category="appliances"
                        :class="{ 'active': itemsActive.appliances }" @click="activeItem(3)">
                        <img :src="appliancesImage">
                    </a>
                    <div><span>{{ resx.Appliances }}</span></div>
                </div>
                <div class="col-lg-4 col-6 garage-clean-out category">
                    <a href="#" id="add-item-cat-house-cleanout" data-category="house-cleanout"
                        :class="{ 'active': itemsActive.houseCleanout }" @click="activeItem(4)">
                        <img :src="houseCleanoutImage">
                    </a>
                    <div><span>{{ resx.HouseOut }}</span></div>
                </div>
                <div class="col-lg-4 col-6 concrete category">
                    <a href="#" id="add-item-cat-concrete" data-category="concrete"
                        :class="{ 'active': itemsActive.concrete }" @click="activeItem(5)">
                        <img :src="concreteImage">
                    </a>
                    <div><span>{{ resx.Concrete }}</span></div>
                </div>
            </div>
            <div class="row bf-truck-hauling-comments">
                <div class="col-lg-12 col-md-12 bf-comment-heading">
                    <p class="m-0" style="font-size: 16px;">{{ resx.S3Message }}
                    </p>
                </div>
                <div class="col-md-12">
                    <textarea id="bf-comments"
                        :placeholder="resx.S3TextAreaPlaceHolder"
                        data-validate-as="" data-error="" v-model="bfCommentsLocal">
                </textarea>
                </div>
            </div>
            <div class="row button-block">
                <div class="col-sm-6 discount-info-col">
                    <h3 class="online-discount-display">{{ resx.BookNow }} &amp;&nbsp;<span class="jk-gold">{{ resx.Save }} <span
                                class="online-discount-amount jk-gold">{{ jkDiscount }}</span>*!</span></h3><span>*{{ resx.ExcludeJobs }} $99 {{ resx.AndUnder }}</span>
                </div>
                <div class="col-sm-6">
                    <button class="btn btn-block" id="btn-book-it" type="button" :class="{ 'disabled': steep3BtnDisabled }"
                        @click="goToStep(4)">
                        {{ resx.ButtonBookIt }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import jk_booking_form_items_01_household_items_01a from '@/assets/jk-booking-form-items-01-household-items-01a.png';
import jk_booking_form_items_02_furniture_01a from '@/assets/jk-booking-form-items-02-furniture-01a.png';
import yardDebrisImage from '@/assets/jk-booking-form-items-03-yard-debris-01a.png';
import appliancesImage from '@/assets/jk-booking-form-items-04-appliances-01a.png';
import houseCleanoutImage from '@/assets/jk-booking-form-items-05-house-garage-attic-clean-out-01a.png';
import concreteImage from '@/assets/jk-booking-form-items-06-concrete-dirt-gravel-01a.png';

import { ref, getCurrentInstance, onMounted, inject } from 'vue';

const resx = inject("resx");

const { jkLocation, bfComments, customerItems,jkDiscount } = defineProps({
    jkLocation: String,
    bfComments: String,
    customerItems: Array,
    jkDiscount:String
});

const itemsActive = ref({
    household: false,
    furniture: false,
    yardDebris: false,
    appliances: false,
    houseCleanout: false,
    concrete: false
});
const steep3BtnDisabled = ref(true);
const bfCommentsLocal = ref(bfComments);
const customerItemsLocal = ref(customerItems);

onMounted(() => {
    steep3BtnDisabled.value = customerItemsLocal.value.length > 0 ? false : true;
    if (customerItemsLocal.value.length > 0) {
        if (customerItemsLocal.value.includes('household')) {
            itemsActive.value.household = true;
        }
        if (customerItemsLocal.value.includes('furniture')) {
            itemsActive.value.furniture = true;
        }
        if (customerItemsLocal.value.includes('yard-debris')) {
            itemsActive.value.yardDebris = true;
        }
        if (customerItemsLocal.value.includes('appliances')) {
            itemsActive.value.appliances = true;
        }
        if (customerItemsLocal.value.includes('house-cleanout')) {
            itemsActive.value.houseCleanout = true;
        }
        if (customerItemsLocal.value.includes('concrete')) {
            itemsActive.value.concrete = true;
        }
    }
});

const { emit } = getCurrentInstance();

const goToStep = (step) => {
    emit('setBfComments', bfCommentsLocal.value);
    emit('goToStep', step);
}

const activeItem = (index) => {
    if (index === 0) {
        itemsActive.value.household = !itemsActive.value.household;
        if (itemsActive.value.household) {
            customerItemsLocal.value.push('household');
        } else {
            customerItemsLocal.value = customerItemsLocal.value.filter(item => item !== 'household');
        }
    } else if (index === 1) {
        itemsActive.value.furniture = !itemsActive.value.furniture;
        if (itemsActive.value.furniture) {
            customerItemsLocal.value.push('furniture');
        } else {
            customerItemsLocal.value = customerItemsLocal.value.filter(item => item !== 'furniture');
        }
    } else if (index === 2) {
        itemsActive.value.yardDebris = !itemsActive.value.yardDebris;
        if (itemsActive.value.yardDebris) {
            customerItemsLocal.value.push('yard-debris');
        } else {
            customerItemsLocal.value = customerItemsLocal.value.filter(item => item !== 'yard-debris');
        }
    } else if (index === 3) {
        itemsActive.value.appliances = !itemsActive.value.appliances;
        if (itemsActive.value.appliances) {
            customerItemsLocal.value.push('appliances');
        } else {
            customerItemsLocal.value = customerItemsLocal.value.filter(item => item !== 'appliances');
        }
    } else if (index === 4) {
        itemsActive.value.houseCleanout = !itemsActive.value.houseCleanout;
        if (itemsActive.value.houseCleanout) {
            customerItemsLocal.value.push('house-cleanout');
        } else {
            customerItemsLocal.value = customerItemsLocal.value.filter(item => item !== 'house-cleanout');
        }
    } else if (index === 5) {
        itemsActive.value.concrete = !itemsActive.value.concrete;
        if (itemsActive.value.concrete) {
            customerItemsLocal.value.push('concrete');
        } else {
            customerItemsLocal.value = customerItemsLocal.value.filter(item => item !== 'concrete');
        }
    }

    if (customerItemsLocal.value.length > 0) {
        steep3BtnDisabled.value = false;
    } else {
        steep3BtnDisabled.value = true;
    }

    emit('setCustomerItems', customerItemsLocal.value);
    
}
</script>
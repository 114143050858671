<template>
    <div class="modal-body step step-2 service-selection" id="service-selection">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-md-12">
                    <h2 class="p-0 m-0">{{ resx.ChooseYourService }}</h2>
                    <h4><span class="jk-location"><i class="fa fa-map-marker-alt"></i>
                            {{ jkLocation }}</span><span>&nbsp;(</span><span class="jk-zip-code">{{ zipCode
                            }}</span><span>)</span></h4>
                </div>
            </div>
            <div class="row selection-options">
                <div class="col-sm-6" id="full-service">
                    <div class="panel panel-default" id="panel-full-service">
                        <div class="panel-body bg-light">
                            <div>
                                <div class="row ">
                                    <div class="col-md-12">
                                        <div class="d-md-none hidden-sm">
                                            <h2>{{ resx.FullService }}</h2>
                                            <p style="font-weight: bold;">{{ resx.WeDoAllWork }}</p>
                                        </div><img class="img-fluid" :src="isJw ? jw_full_service : full_service">
                                    </div>
                                    <div class="col-md-12">
                                        <div class="d-none d-md-block">
                                            <h2>{{ resx.FullService }}</h2>
                                            <p style="font-weight: bold;">{{ resx.WeDoAllWork }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inner-shadow service-bullets">
                                            <ul style="margin-right:20px;">
                                                <li>{{ resx.S2L1FastFrienly }}</li>
                                                <li>{{ resx.S2L1FreeUpfront }}</li>
                                                <li>{{ resx.S2L1PayOnly }}</li>
                                                <li>{{ resx.S2L1LowPrice }}</li>
                                                <li>90% {{ resx.S2L1TimeClose }}</li>
                                                <li>1 <span class="bspan">{{ resx.JunkKing }}</span> {{ resx.TruckLower
                                                    }} = 6 {{
                        resx.S2L1Pickup }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer">
                            <button class="btn btn-success btn-block" id="btn-full-service" type="button"
                                @click="goToStep(3)"> {{ resx.ComeGetMyItems }}</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6" id="self-service">
                    <div class="panel panel-default" id="panel-self-service">
                        <div class="panel-body bg-light">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="d-md-none hidden-sm">
                                            <h2>{{ resx.SelfService }}</h2>
                                            <p style="font-weight: bold;">3-{{ resx.DayDumpsterRental }}</p>
                                        </div><img class="img-fluid" :src="isJw ? jw_dumpster : dumpster">
                                    </div>
                                    <div class="col-md-12">
                                        <div class="d-none d-md-block">
                                            <h2>{{ resx.SelfService }}</h2>
                                            <p style="font-weight: bold;">3-{{ resx.DayDumpsterRental }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="inner-shadow service-bullets">
                                            <ul>
                                                <li>{{ resx.S2L2Fair }}</li>
                                                <li>{{ resx.S2L2Convenient }}</li>
                                                <li>{{ resx.S2L1PayOnly }}</li>
                                                <li>{{ resx.S2L2Driveway }}</li>
                                                <li>{{ resx.S2L2Ranked }}</li>
                                                <li>1 <span class="bcspan">{{ resx.JunkKing }}</span> {{
                        resx.DumpsterLower }} =
                                                    4 {{ resx.S2L1Pickup }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer">
                            <button class="btn btn-success btn-block" id="btn-jk-dumpster" type="button"
                                @click="goToStep(10)">
                                {{ resx.ButtonIDoMySelf }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import full_service from '@/assets/jk-service-full-service.jpg';
import dumpster from '@/assets/jk-service-dumpster.jpg';

import jw_full_service from '@/assets/jw-service-full-service.jpg';
import jw_dumpster from '@/assets/jw-service-dumpster.jpg';
import { getCurrentInstance, inject } from 'vue';

const resx = inject("resx");

const { jkLocation, zipCode, isJw } = defineProps({
    jkLocation: String,
    zipCode: String,
    isJw: Boolean
});

const { emit } = getCurrentInstance();

const goToStep = (step) => {
    if (step === 10) {
        emit('setIsDumpster', true);
    } else {
        emit('setIsDumpster', false);
    }
    emit('goToStep', step);
}
</script>
<template>
    <div class="modal fade" id="dbModal" tabindex="-1" aria-labelledby="dbModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-bg-red">
                    <h5 class="modal-title text-white font-size-23" id="dbModalLabel">
                        {{ resx.PurchaseJKDumpsterBag }}
                    </h5>
                    <button @click="close" type="button" id="btn-header-close-modal" class="btn btn-m-close"
                        data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa fa-close text-white"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="!showResult" id="modalForm" class="p-1">
                        <p class="font-size-18">
                            {{ resx.EnterYourZip }}
                        </p>
                        <div class="row pos-relative">
                            <div class="col-auto">
                                <label for="textZipCode" class="form-label font-bold">
                                    {{ resx.ZipPostalCode }}
                                </label>
                                <input v-model="zipCode" type="text" class="form-control" id="textZipCode"
                                    @keyup.enter="getDumsterInfo">
                            </div>
                            <div class="col-auto">
                                <button @click="getDumsterInfo" :disable="isLoading" type="button" id="btnVerify"
                                    class="btn btn-green pos-end">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        v-if="isLoading"></span>
                                    {{ resx.Verify }}
                                </button>
                            </div>
                        </div>

                        <div v-if="showZipCodeError" class="mt-1">
                            <p class="font-size-18" id="NoFoundMessage">
                                {{ resx.WeSorry }}
                            </p>
                        </div>
                    </div>
                    <div v-if="showResult" id="modalResultOk" class="p-1">
                        <p class="font-size-18">{{ resx.GoodNews }} {{ resx.Weservice }}</p>
                        <a href="https://junkkingbags.coversandall.com/dumpster-bag-junk-king/" target="_blank"
                            class="btn btn-green font-size-18">
                            {{ resx.BuyYourJKDumpsterBag }}
                        </a>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="close" id="btn-footer-close-modal" type="button" class="btn btn-light"
                        data-bs-dismiss="modal">{{ resx.Close }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { postAsync } from "@/assets/api";
const dnnConfig = inject("dnnConfig");
const resx = inject("resx");

const zipCode = ref('');
const showZipCodeError = ref(false);
const showResult = ref(false);
const isLoading = ref(false);

const validateZip = () => {
    const zipCodeRegex = /^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]|[0-9]{5}$/;
    zipCode.value = zipCode.value.replace(/([a-z]\d[a-z])(\d[a-z]\d)/gi, '$1 $2');
    if (zipCode.value == '' || !zipCodeRegex.test(zipCode.value)) {
        return false;
    }
    return true;
}

const getDumsterInfo = async () => {
    if (validateZip()) {
        isLoading.value = true;
        postAsync('BookOnline/GetDumpsterBagInfo', { Zip: zipCode.value }, dnnConfig)
            .then(response => {
                isLoading.value = false;
                if (response.Result != null) {
                    showZipCodeError.value = false;
                    showResult.value = true;
                } else {
                    showZipCodeError.value = true;
                }
            })
            .catch(() => {
                isLoading.value = false;
                alert(resx.ServerError);
            });
    } else {
        alert(resx.ZipInvalidMessage)
    }
}

const close = () => {
    showResult.value = false;
    showZipCodeError.value = false;
    zipCode.value = '';
}
</script>
<template>
    <div class="modal-body step step-7" id="service-book">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-sm-12 text-left">
                    <div class="online-discount-display">
                        <h2 style="font-size: 33px;" class="p-0 m-0">{{ resx.BookNow }} &amp;&nbsp;<span class="jk-gold">{{
                            resx.Save }} $<span class="online-discount-amount jk-gold">20</span>!</span> <span>*</span>
                        </h2>
                        <p style="font-size: 12px;" class="small m-0">* {{ resx.Excludesjobs }}</p>
                    </div>
                </div>
            </div>
            <div class="row subheader-block">
                <div class="col-8 text-left">
                    <h5 class="mt-0"><span class="jk-location"><i class="fa fa-map-marker-alt"></i> {{ jkLocation
                    }}</span><span>&nbsp;(</span><span class="jk-zip-code">{{ zipCode }}</span><span>)</span></h5>
                </div>
                <div class="col-4">
                    <div class="dropdown pe-dropdown" v-if="!isDumpster">
                        <button class="btn btn-link btn-myitems" data-bs-toggle="dropdown" aria-expanded="false"
                            type="button">{{ resx.MyItems }}&nbsp;<i class="fa fa-chevron-circle-down"
                                style="margin-left: 8;"></i></button>
                        <ul class="dropdown-menu pe-dropdown-menu">
                            <li v-for="item in myItems" :key="item.Id" id="pickup-trucks" style="font-size: 14px;">
                                <a href="#" class="dd-item" data-slide="1" data-screen="4">
                                    <span class="count">{{ item.Count }}</span>
                                    &nbsp;
                                    <span class="item-name">{{ item.Name }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row bg-light estimate-bar" style="margin: 0px -30px;" v-if="!isDumpster">
                <div class="col-12 text-center" style="text-align:center!Important;">
                    <h4><span class="d-none d-sm-inline">{{ resx.Your }}&nbsp;</span><span>{{ resx.Estimate
                    }}:&nbsp;</span><span class="jk-red final-price-range price-range"><span
                                class="jk-red">${{ startPrice }}-${{ endPrice }}</span><span
                                class="jk-black d-none d-sm-inline">
                                (-$20)</span></span></h4>
                </div>
            </div>
            <div id="pe-booking-form" class="book-form">
                <div class="row">
                    <div class="col-md-12">
                        <h4>{{ resx.LetsGettheDetails }}:</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 g-1">
                        <input type="text" class="form-control" :placeholder="resx.FormPlaceholderName"
                            :aria-label="resx.FormPlaceholderName" v-model="formModel.Name" @blur="validateName"
                            :class="showValidates.Name ? (formErrors.Name ? 'frm-invalid' : 'frm-valid') : ''" />
                        <span class="small text-white" v-if="formErrors.Name">{{ formErrors.Name }}</span>
                    </div>
                    <div class="col-6 g-1">
                        <input type="text" class="form-control" :placeholder="resx.FormPlaceholderEmail"
                            :aria-label="resx.FormPlaceholderEmail" v-model="formModel.Email" @blur="validateEmail"
                            :class="showValidates.Email ? (formErrors.Email ? 'frm-invalid' : 'frm-valid') : ''" />
                        <span class="small text-white" v-if="formErrors.Email">{{ formErrors.Email }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 g-1">
                        <input type="text" class="form-control" :placeholder="resx.FormPlaceholderAddress"
                            :aria-label="resx.FormPlaceholderAddress" v-model="formModel.Address" @blur="validateAddress"
                            :class="showValidates.Address ? (formErrors.Address ? 'frm-invalid' : 'frm-valid') : ''" />
                        <span class="small text-white" v-if="formErrors.Address">{{ formErrors.Address }}</span>
                    </div>
                    <div class="col-6 g-1">
                        <input type="text" class="form-control" :placeholder="resx.FormPlaceholderPhone"
                            :aria-label="resx.FormPlaceholderPhone" v-model="formModel.Phone" @blur="validatePhone"
                            :class="showValidates.Phone ? (formErrors.Phone ? 'frm-invalid' : 'frm-valid') : ''" />
                        <span class="small text-white" v-if="formErrors.Phone">{{ formErrors.Phone }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 g-1">
                        <VueDatePicker v-model="formModel.Date" week-start="0" auto-apply :min-date="new Date()" :enable-time-picker="false"
                            model-type="MM/dd/yyyy" @closed="getTimes" placeholder="Date"
                            :input-class-name="showValidates.Date ? (formErrors.Date ? 'frm-invalid' : 'frm-valid') : ''" />
                        <span class="small text-white" v-if="formErrors.Date">{{ formErrors.Date }}</span>
                    </div>
                    <div class="col-6 g-1">
                        <select class="form-select" aria-label="TIME" v-model="formModel.Time" @blur="validateTime"
                            placeholder="--Select Time--"
                            :class="showValidates.Time ? (formErrors.Time ? 'frm-invalid' : 'frm-valid') : ''">
                            <option v-for="time in times" :key="time.Value" :value="time.Value">{{ time.Text }}</option>
                        </select>
                        <span class="small text-white" v-if="formErrors.Time">{{ formErrors.Time }}</span>
                    </div>
                </div>
                <div id="pe-dumpster-tc-cb-row" class="row" v-if="isDumpster">
                    <div class="col-6 col-md-12 g-1 d-flex justify-content-center">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="bf-accept-tac-cb"
                                   v-model="acepTerms">
                            <label class="form-check-label" for="bf-accept-tac-cb">
                                &nbsp;{{ resx.Iacceptthedumpsterrental }}
                            </label>
                            &nbsp;<a class="dtac-link"
                                     :href="dtacLink" target="_blank"
                                     style="text-decoration: underline;">
                                {{ resx.Terms }}
                            </a>.
                        </div>
                    </div>
                </div>
                <div class="row button-block">
                    <div class="col-sm-3 col-1 d-none d-sm-block"></div>
                    <div class="col-sm-6 col-12">
                        <button class="btn btn-success btn-block book-it-btn" id="btn-book-appointment" type="button"
                            @click="goToSteep()" :disabled="isLoading">
                            {{ btnSend }}
                        </button>
                    </div>
                    <div class="col-sm-3 col-1 d-none d-sm-block"></div>
                </div>
                <div class="row button-block d-none">
                    <div class="col-md-12">
                        <p style="font-size: 14px;" class="mf-msg">{{ resx.P7Info }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, inject, getCurrentInstance } from 'vue';
import { postAsync, readCookie, logEvent, readLocalCookie, ConversionTracking } from "@/assets/api";
import VueDatePicker from '@vuepic/vue-datepicker';

const resx = inject("resx");
const dnnConfig = inject("dnnConfig");
const { emit } = getCurrentInstance();
const { jkLocation, zipCode, isDumpster, acepTermsandConditions, customerInfo, selectTimes, maxPrice,
    myItems, startPrice, endPrice, isTruck, truckStr, zvres, dtacLink } = defineProps({
        jkLocation: String,
        zipCode: String,
        isDumpster: Boolean,
        acepTermsandConditions: Boolean,
        customerInfo: Object,
        selectTimes: Array,
        maxPrice: String,
        myItems: Array,
        startPrice: Number,
        endPrice: Number,
        isTruck: Boolean,
        truckStr: String,
        zvres: Object,
        dtacLink: String
    });

const btnSend = isDumpster ? resx.Next : resx.BookIt;
const isLoading = ref(false);
const acepTerms = ref(acepTermsandConditions);
const formModel = ref(customerInfo);
const formErrors = ref({
    Name: '',
    Address: '',
    Phone: '',
    Email: '',
    Date: '',
    Time: '',
});
const showValidates = ref({
    Name: false,
    Address: false,
    Phone: false,
    Email: false,
    Date: false,
    Time: false,
});
const times = ref(selectTimes);

const updateTimeText = () => {
    if (formModel.value.Time != '') {
        var time = times.value.find(x => x.Value == formModel.value.Time);
        emit('setTimeText', time.Text);
    }
}


const getPickUpItemsNotes = () => {
    var formattedItems = myItems.map(item => `(${item.Count})${item.Name}`).join(', ');
    var notes = `${formattedItems}, price: $${startPrice} - $${endPrice} - Online Pricing Estimator`;
    return notes;
}

const saveAppointment = async () => {
    isLoading.value = true;
    initShowValidate();
    //call saveapoiment api
    var nparts = formModel.value.Name.split(' ');
    var fname = nparts[0].trim();
    var lname = nparts.length > 2 ? nparts.slice(1).join(' ') : nparts[1];

    var notes = isDumpster ? `JK Dumpster, price: $${maxPrice} - Online Pricing Estimator` : isTruck ? `${truckStr} truck(s), price: $${startPrice} - $${endPrice} - Online Pricing Estimator` : getPickUpItemsNotes();

    var landingPage = '';
    var referringPage = '';

    try {
        var piCookie = await readCookie("page-info",dnnConfig);
        if (piCookie != null) {
            var vobj = JSON.parse(piCookie);
            referringPage = vobj.r;
            landingPage = vobj.lp;
        }
    } catch (ex) {
        //handleJSError(dnnConfig,ex, "SaveAppointment-ReadCookie");
    }

    var addinfo = '';
    try {
            var alCookieVal = readLocalCookie("_adl_id.AL_823");
            if (alCookieVal != null) addinfo += (addinfo == "" ? "" : ";") + "alsid:" + alCookieVal;
        } catch (ex) { 
            //HandleJSError(ex, "SaveAppointment-ReadCookie");
        }

    var request = {
        Fname: fname,
        Lname: lname,
        Phone: formModel.value.Phone,
        Email: formModel.value.Email,
        Address: formModel.value.Address,
        Zip: zipCode,
        Date: formModel.value.Date,
        Time: formModel.value.Time,
        ItemList: '',
        Notes: notes,
        HowHeard: '',
        HowHeardOther: '',
        AppointmentTypeId: '1',
        UserAgent: navigator.userAgent,
        BoUrl: window.location.href,
        LandingPage: landingPage,
        ReferringPage: referringPage,
        AdditionalInfo: addinfo,
        IsDumpster: isDumpster
    };
    logEvent("BookOnline: SaveAppointment-Start", request.Email + '|' + request.Zip + '|' + request.Date + '|' + request.Time, dnnConfig);
    postAsync('BookOnlineExpress/SaveAppointment', request, dnnConfig)
        .then(response => {
            emit('setConfNumber', response.Result.JKNumber);
            emit('setCustomerInfo', formModel.value);
            emit('setSelectTimes', times.value);
            emit('setTerms', acepTerms.value);
            ConversionTracking(zvres, response.Result.JKNumber);
            updateTimeText();
            logEvent("BookOnline: SaveAppointment-Success", "JK #: " + response.Result.JKNumber, dnnConfig);
            isLoading.value = false;
            var step = 8;
            if (isDumpster) {
                step = 9;
            } else {
                emit('setShowBackBtn', false);
            }
            emit('goToStep', step);
        })
        .catch(() => {
            isLoading.value = false;
            alert(resx.JunkSaverAppointmentError);
            //handleJSError(dnnConfig,ex, "SaveAppointment");
        });
}

const goToSteep = async () => {
    if (validateForm()) {
        if (isDumpster && !acepTerms.value) {
            alert(resx.P7AcceptMessage);
            return;
        }
        await saveAppointment();
    }
}

const initShowValidate = () => {
    showValidates.value = {
        Name: false,
        Address: false,
        Phone: false,
        Email: false,
        Date: false,
        Time: false,
    }
}

//validations
const validateDate = () => {
    showValidates.value.Date = true;
    if (formModel.value.Date == null || formModel.value.Date == "") {
        formErrors.value.Date = resx.ValDate;
    } else {
        formErrors.value.Date = "";
    }
}

const getTimes = async () => {
    validateDate();
    if (formModel.value.Date != null && formModel.value.Date != "") {
        var request = {
            Date: formModel.value.Date,
            Zip: zipCode,
            IsDumpster: isDumpster
        }
        postAsync("BookOnlineExpress/GetAvailableTimes", request, dnnConfig)
            .then(response => {
                if (response.StatusCode == 200) {
                    times.value = response.Result.Option;
                    logEvent("BookOnline: GetAvailableTimesSuccess",  JSON.stringify({ results: response.Result.Option.filter(option => option.Value !== null).map(option => option.Value).join(', '), date: formModel.value.Date, zip: zipCode }), dnnConfig);
                }
            })
            .catch(() => {
                alert(resx.ServerError);
            });
    } else {
        times.value = [];
        formModel.value.Date = '';
        formErrors.value.Date = resx.ValDate;
        alert(resx.JunkCallMessage);
    }

}
const validateName = () => {
    showValidates.value.Name = true;
    const nameRegex = /^[A-Za-z]+ [A-Za-z]+$/;
    if (!nameRegex.test(formModel.value.Name)) {
        formErrors.value.Name = resx.ValName;
    } else {
        formErrors.value.Name = '';
    }
};

function validatePhone() {
    showValidates.value.Phone = true;
    formModel.value.Phone = formModel.value.Phone.trim();
    if (formModel.value.Phone == 'PHONE' || formModel.value.Phone == '') {
        formErrors.value.Phone = resx.ValPhoneReq;
    } else if (!formModel.value.Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        formErrors.value.Phone = resx.ValPhoneInvalid;
    }
    else {
        formErrors.value.Phone = '';
    }
}

function validateEmail() {
    showValidates.value.Email = true;
    formModel.value.Email = formModel.value.Email.trim();
    if (formModel.value.Email == 'EMAIL' || formModel.value.Email == '') {
        formErrors.value.Email = resx.ValEmailReq;
    } else if (!formModel.value.Email.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)) {
        formErrors.value.Email = resx.ValEmailInvalid;
    } else {
        formErrors.value.Email = '';
    }
}

let validateAddress = () => {
    showValidates.value.Address = true;
    formModel.value.Address = formModel.value.Address.trim();
    if (formModel.value.Address == 'ADDRESS' || formModel.value.Address == '') {
        formErrors.value.Address = resx.ValAddressReq;
    } else if (formModel.value.Address.split(' ').length < 2) {
        formErrors.value.Address = resx.ValAddressInvalid;
    } else {
        formErrors.value.Address = '';
    }
}

let validateTime = () => {
    showValidates.value.Time = true;
    if (formModel.value.Time == '') {
        formErrors.value.Time = resx.ValTimeReq;
    } else {
        formErrors.value.Time = '';
    }
}

//format phone
let formatPhoneNumber = (value) => {
    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
}


let validateForm = () => {
    validateName();
    validateAddress();
    validatePhone();
    validateEmail();
    validateDate();
    validateTime();

    let valid = true;
    for (const key in formErrors.value) {
        if (formErrors.value[key] != '') {
            valid = false;
            break;
        }
    }
    return valid;
}
watch(formModel, () => {
    formModel.value.Phone = formatPhoneNumber(formModel.value.Phone);
}, { deep: true });
</script>
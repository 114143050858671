<template>
    <div class="modal-body step step-7 service-selection" id="service-dumpster-info">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-md-12 text-center">
                    <h4><span class="jk-location"><i class="fa fa-map-marker-alt mr-1"></i>
                            {{ jkLocation }}</span>&nbsp;(<span class="jk-zip-code">{{
                                zipCode }}</span>)</h4>
                    <h2 class="p-0 m-0 jk-gold">{{ resx.DumpsterRental }}</h2>
                    <h3>3-{{ resx.DayDumpster }}</h3>
                </div>
            </div>
        </div>
        <div class="row bg-light estimate-bar">
            <div class="col-12" style="text-align:center!important;">
                <h3>{{ resx.OnlyPay }}</h3>
                <div class="row" style="padding:0px 20px 0px 20px;">
                    <div class="col-6" style="text-align:center;">
                        <h4>{{resx.Minimum}}<br><span class="jk-red" id="dr-min-price">${{ minPrice }}</span></h4>
                    </div>
                    <div class="col-6" style="text-align:center;">
                        <h4>{{resx.Full}}<br><span class="jk-red" id="dr-full-price">${{ maxPrice }}</span></h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="col-12">
                <div style="font-size: 20px !important; padding: 10px 0px 0px 0px;">
                    <h5>{{ resx.UseCreditCard }}</h5>
                </div>
            </div>
        </div>

        <div class="row button-block">
            <div class="col-sm-3 col-1 d-none d-md-block"></div>
            <div class="col-sm-6 col-12">
                <button class="btn btn-success btn-block" id="btn-book-dr" type="button"    @click="goToStep(7)">
                    {{resx.ScheduleDrop}}
                </button>
            </div>
            <div class="col-sm-3 col-1 d-none d-md-block"></div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, inject } from 'vue';

const resx = inject("resx");

const { jkLocation, zipCode, minPrice, maxPrice } = defineProps({
    jkLocation: String,
    zipCode: String,
    minPrice: String,
    maxPrice: String
});
const { emit } = getCurrentInstance();

const goToStep = (step) => {
    emit('goToStep', step);
}
</script>
<template>
    <div class="modal-body step step-6" id="service-ai-main-ui">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-sm-6">
                    <h2>{{ resx.AddYourItems }}</h2>
                </div>
                <div class="col-sm-6 d-none d-sm-block pr-0">
                    <p style="font-size: 14px;">{{ resx.P6AddInfo }}</p>
                </div>
            </div>
            <div class="row subheader-block">
                <div class="col-8">
                    <h5 class="mt-0"><span class="jk-location"><i class="fa fa-map-marker-alt"></i> {{ jkLocation
                    }}</span><span>&nbsp;(</span><span class="jk-zip-code">{{ zipCode }}</span><span>)</span></h5>
                </div>
                <div class="col-4">
                    <div class="dropdown pe-dropdown"><button class="btn btn-link btn-myitems" data-bs-toggle="dropdown"
                            aria-expanded="false" type="button">{{ resx.MyItems }} &nbsp;<i class="fa fa-chevron-circle-down"
                                style="margin-left: 8;"></i></button>
                        <ul class="dropdown-menu pe-dropdown-menu">
                            <li v-for="item in myItems" :key="item.Id" id="Couchwsleeperrecliner" style="font-size: 14px;">
                                <a href="#" class="dd-item" data-slide="0" data-screen="6" data-category="undefined">
                                    <span class="count">{{ item.Count }}</span>
                                    &nbsp;<span class="item-name">{{ item.Name }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row vue-carousel">
                <div class="col-12">
                    <Carousel :breakpoints="breakpoints">
                        <Slide v-for="(categ, index) in categories" :key="index">
                            <div style="padding: 5px;">
                                <a href="#" data-category="Couches &amp; Chairs" data-slide="0">
                                    <img class="img-fluid img-ico" :src="getIco(categ.Icon)"
                                        :class="{ active: activeSlideIndex === index }" @click="loadData(index)">
                                </a>
                                <span> {{ categ.Name }}</span>
                            </div>

                        </Slide>

                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                </div>
            </div>

            <div class="row bg-light estimate-bar">
                <div class="col-9 text-left">
                    <h4><span class="d-none d-sm-inline">{{ resx.Your }}&nbsp;</span><span>{{ resx.Estimate }}:&nbsp;</span><span
                            class="jk-red ai-price-range price-range">{{ rangePrice }}</span></h4>
                </div>
                <div class="col-3">
                    <button @click="clearItems" class="btn btn-link btn-sm btn-reset btn-reset-ai" type="button">
                        <span>{{ resx.Clear }}&nbsp;</span><span class="d-none d-sm-inline">{{ resx.Items }}&nbsp;</span>
                        <i class="fa fa-rotate-left jk-red"></i>
                    </button>
                </div>
            </div>
            <div class="row inner-shadow bg-light" id="add-items-list" style="height: 200px;">
                <div class="col-md-12">
                    <div class="lSSlideOuter ">
                        <div class="lSSlideWrapper usingCss on">
                            <ul id="items-slider" class="items-slider lightSlider lSFade"
                                style="height: 0px; transition-duration: 400ms; transition-timing-function: ease; list-style: none;">

                                <li class="lslide slide-0 active" id="Couches &amp; Chairs" style="">
                                    <div class="row">
                                        <div class="col-sm-6 items-col">
                                            <ul>
                                                <li v-for="item in firstColumnCategories" :key="item.Name" class="item"
                                                    data-toggle="tooltip" :title="item.Name"
                                                    :class="{ selected: item.Count > 0 }">
                                                    <span class="item-count">{{ item.Count }}</span><span
                                                        class="item-name">{{ item.Name }}</span>
                                                    <div role="group" class="btn-group item-data" data-slide="0"
                                                        data-volume="0.015152" data-sioffset="-10" data-category="undefined"
                                                        data-parent="undefined" data-item-name="Chair"
                                                        data-item-totalcount="0" data-item-totalvolume="0">
                                                        <a @click="removeItem(item.Id)" class="btn btn-sm" role="button"
                                                            data-action="remove-item">
                                                            <i class="fa fa-minus"></i>
                                                        </a>
                                                        <a @click="addItem(item.Id)"
                                                            class="btn btn-sm btn-primary text-white" role="button"
                                                            data-action="add-item">
                                                            <i class="fa fa-plus"></i>
                                                        </a>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div class="col-sm-6 items-col">
                                            <ul>
                                                <li v-for="item in secondColumnCategories" :key="item.Name" class="item"
                                                    data-toggle="tooltip" :title="item.Name"
                                                    :class="{ selected: item.Count > 0 }">
                                                    <span class="item-count">{{ item.Count }}</span><span
                                                        class="item-name">{{ item.Name }}</span>
                                                    <div role="group" class="btn-group item-data" data-slide="0"
                                                        data-volume="0.015152" data-sioffset="-10" data-category="undefined"
                                                        data-parent="undefined" data-item-name="Chair"
                                                        data-item-totalcount="0" data-item-totalvolume="0">
                                                        <a @click="removeItem(item.Id)" class="btn btn-sm" role="button"
                                                            data-action="remove-item">
                                                            <i class="fa fa-minus"></i>
                                                        </a>
                                                        <a @click="addItem(item.Id)"
                                                            class="btn btn-sm btn-primary text-white" role="button"
                                                            data-action="add-item">
                                                            <i class="fa fa-plus"></i>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row button-block">
                <div class="col-sm-6">
                    <div class="online-discount-display">
                        <h3 style="font-size: 21px;" class="m-0">{{ resx.BookNow }} &amp;&nbsp;<span class="jk-gold">{{ resx.Save }} $<span
                                    class="online-discount-amount jk-gold">20</span>!</span> <span>*</span></h3>
                        <p style="font-size: 12px;" class="small m-0">* {{ resx.Excludesjobs }}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <button @click="goToStep(7)" class="btn btn-success btn-block" id="btn-book-ai" type="button" :disabled="startingPrice==0">
                        {{ resx.PickTheseUp }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref, getCurrentInstance, defineProps,inject } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import ico_Couches_and_Chairs from '@/assets/ico_Couches_and_Chairs.png';
import ico_Tables_and_Dressers from '@/assets/ico_Tables_and_Dressers.png';
import ico_electronics from '@/assets/ico_electronics.png';
import ico_appliances from '@/assets/ico_appliances.png';
import ico_Mattresses from '@/assets/ico_Mattresses.png';
import ico_miscellaneous from '@/assets/ico_miscellaneous.png';

const resx = inject("resx");

const { emit } = getCurrentInstance();

const props = defineProps({
    jkLocation: String,
    zipCode: String,
    categories: Array,
    jHPricing: Array,
    peRangeLow: Number,
    peRangeHigh: Number,
    pickUpItems: Array,
    currentCateg:Number
});
const { jkLocation, zipCode, categories, jHPricing, peRangeLow, peRangeHigh, pickUpItems, currentCateg } = props;

const getIco = (ico) => {
    switch (ico) {
        case 'Couches_and_Chairs':
            return ico_Couches_and_Chairs;
        case 'Tables_and_Dressers':
            return ico_Tables_and_Dressers;
        case 'electronics':
            return ico_electronics;
        case 'appliances':
            return ico_appliances;
        case 'Mattresses':
            return ico_Mattresses;
        default: return ico_miscellaneous
    }
}

const goToStep = (steep) =>{
    emit('goToStep', steep)
}

const activeSlideIndex = ref(0);
const firstColumnCategories = ref([]);
const secondColumnCategories = ref([]);
const myItems = ref([]);
const siOffset = ref(0);
const startingPrice = ref(0);
const endingPrice = ref(0);
const rangePrice = ref('$0');
const calPrices = () => {
    var calculatedVolume = items.value.reduce((acc, item) => acc + (item.Count * item.Volume), 0);
    var itemCount = items.value.reduce((acc, item) => acc + item.Count, 0);


    var pIdx = -1;
    if (itemCount > 0) {

        if (itemCount == 1) siOffset.value = items.value.find((item) => item.Count > 0).Offset;
        else siOffset.value = 0;

        var pricing = jHPricing;

        if (calculatedVolume < .083) pIdx = 0;
        else if (calculatedVolume < .125) pIdx = 1;
        else pIdx = Math.floor((calculatedVolume * 12).toFixed(2)) + 1;

        var basePrice = pIdx >= pricing.length ? pricing[pricing.length - 1].EndingPrice : pricing[pIdx].StartingPrice;
        var foffset = itemCount == 1 ? siOffset.value : (calculatedVolume > .333 ? peRangeHigh : peRangeLow);

        startingPrice.value = Math.floor(foffset >= 0 ? basePrice : basePrice + foffset);
        endingPrice.value = Math.floor(foffset >= 0 ? basePrice + foffset : basePrice);

        if (calculatedVolume <= .333 && itemCount > 1) {
            startingPrice.value = Math.max(pricing[0].StartingPrice, startingPrice.value);
        }
    } else {
        startingPrice.value = 0;
        endingPrice.value = 0;
    }

    if (startingPrice.value == 0 && endingPrice.value == 0) {
        rangePrice.value = '$0';
    } else {
        if (startingPrice.value === endingPrice.value) rangePrice.value = `$${startingPrice.value}`;
        else
            rangePrice.value = `$${startingPrice.value} - $${endingPrice.value}`;
    }

    emit('updatePickUpItems', items.value, myItems.value, startingPrice.value, endingPrice.value);
}


const updateMyItems = () => {
    myItems.value = items.value.filter((item) => item.Count > 0);
    calPrices();
}

const clearItems = () => {
    items.value.forEach((item) => item.Count = 0);
    items.value = [...items.value];
    updateMyItems();
    startingPrice.value = 0;
    endingPrice.value = 0;
    rangePrice.value = '$0';
}


const loadData = (index) => {
    activeSlideIndex.value = index;
    var list = items.value.filter((item) => item.ParentId === index);
    const midpoint = Math.ceil(list.length / 2);
    firstColumnCategories.value = list.slice(0, midpoint);
    secondColumnCategories.value = list.slice(midpoint);
    emit('updateCurrentCateg', index);
}

const calcTotalVolumen = () => {

    var total = myItems.value.reduce((acc, item) => acc + (item.Count * item.Volume), 0);
    return total;
}

const addItem = (id) => {
    const item = items.value.find((item) => item.Id === id);
    if (calcTotalVolumen() + item.Volume > 1) {
        emit('showModalError');
        return;
    }

    item.Count++;
    items.value = [...items.value];

    updateMyItems();
}

const removeItem = (id) => {
    const item = items.value.find((item) => item.Id === id);
    if (item.Count > 0) {
        item.Count--;
        items.value = [...items.value];
    }
    updateMyItems();
}

const items = ref([]);

onMounted(() => {
    initItems();
    loadData(currentCateg);
});

const initItems = () => {
    if(pickUpItems.length>0){
        items.value = pickUpItems;
        updateMyItems();
        return;
    }

    var i = 0;
    categories.forEach((categ, index) => {
        categ.Items.forEach((item) => {
            items.value.push({
                Id: i,
                ParentId: index,
                Count: 0,
                Category: categ.Name,
                Name: item.Name,
                Volume: item.Volume,
                Offset: item.Offset,
                Icon: item.Icon
            });
            i++;
        });
    });
};

const breakpoints = ref({
    480: {
        itemsToShow: 3,
        itemsToScroll: 1,
        snapAlign: 'center',
    },
    800: {
        itemsToShow: 4,
        itemsToScroll: 4,
        snapAlign: 'center',
    }
});

</script>
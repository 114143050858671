<template>
    <div class="modal-body step step-4" id="service-truckloads">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-sm-7">
                    <h2 class="p-0 m-0">{{ resx.HowManyLoads }}</h2>
                </div>
                <div class="col-sm-5 d-none d-sm-block pr-0">
                    <p style="font-size: 14px;" class="pt-1 m-0">{{ resx.AddRemoveMessage }}</p>
                </div>
            </div>
            <div class="row subheader-block">
                <div class="col-8">
                    <h5 class="mt-0"><span class="jk-location"><i class="fa fa-map-marker-alt"></i> {{ jkLocation
                    }}</span><span>&nbsp;(</span><span class="jk-zip-code">{{ zipCode }}</span><span>)</span></h5>
                </div>
                <div class="col-4">
                    <div class="dropdown pe-dropdown">
                        <button class="btn btn-link btn-myitems" data-bs-toggle="dropdown" aria-expanded="false"
                            type="button">
                            {{ resx.MyItems }}&nbsp;
                            <i class="fa fa-chevron-circle-down" style="margin-left: 8;"></i>
                        </button>
                        <ul class="dropdown-menu pe-dropdown-menu">
                            <li id="pickup-trucks" style="font-size: 14px;">
                                <a href="#" class="dd-item" data-slide="1" data-screen="4" v-if="truckCount > 0">
                                    <span class="count">{{ truckCountString }}</span>
                                    &nbsp;
                                    <span class="item-name">{{ resx.PickupTruckLoad }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row bg-light" style="background: #fff;">
                <div class="col-md-12">
                    <h6><span>*</span><span class="jk-red">1</span><span>&nbsp;{{ resx.JunkKingTruck }} =&nbsp;</span><span
                            class="jk-red">6&nbsp;</span><span>{{ resx.RegularPickupTruckLoad }}</span></h6>
                </div>
            </div>
            <div class="row bg-light" id="row-pickup-trucks">
                <div class="col-6 truckload" id="pickups-full-counter"><img :src="pickup_truck_full">
                    <div><span class="count jk-red">{{ fullTruckCount }}</span><span>{{ resx.FullPickupTruckLoad }}</span></div>
                    <div class="btn-group" role="group">
                        <a class="btn btn-sm btn-light" role="button" data-action="remove-full" @click="removeFull">
                            <i class="fa fa-minus"></i>
                        </a>
                        <a class="btn btn-sm btn-primary text-white" role="button" data-action="add-full" @click="addFull">
                            <i class="fa fa-plus"></i>
                        </a>
                    </div>
                </div>
                <div class="col-6 truckload" id="pickups-half-counter"><img :src="pickup_truck_half">
                    <div><span class="count jk-red">{{ halfTruckCount }}</span><span>{{ resx.HalfPickupTruckLoad }}</span></div>
                    <div class="btn-group" role="group">
                        <a class="btn btn-sm btn-light" role="button" data-action="remove-half" @click="removeHalf">
                            <i class="fa fa-minus"></i>
                        </a>
                        <a class="btn btn-sm btn-primary text-white" role="button" data-action="add-half" @click="addHalf">
                            <i class="fa fa-plus"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row inner-shadow bg-light">
                <div class="col-md-12">
                    <div id="jk-truck-container">
                        <div class="jk-truck-image" :background-image="junk_king_truck">
                            <div id="progress_bar" class="progress">
                                <div class="progress-bar" role="progressbar" :style="progressVal"></div>
                            </div>
                        </div>
                    </div>
                    <h3><span id="jk-progress-string" class="jk-red">{{ percent }}% </span><span>{{ resx.FULLJUNKKINGTRUCK }}</span>
                    </h3>
                </div>
            </div>
            <div class="row estimate-bar bg-light">
                <div class="col-9 text-left">
                    <h4><span class="d-none d-md-inline">{{ resx.Your }}&nbsp;</span><span>{{ resx.Estimate }}:&nbsp;</span><span
                            class="jk-red tl-price-range price-range"><span class="jk-red">{{ rangePrice }}</span></span></h4>
                </div>
                <div class="col-3">
                    <button class="btn btn-link btn-sm btn-reset btn-reset-tl" type="button" @click="clearItems">
                        <span>{{ resx.Clear }}&nbsp;</span>
                        <span class="d-none d-sm-inline">{{ resx.Items }}&nbsp;</span>
                        <i class="fa fa-rotate-left jk-red"></i>
                    </button>
                </div>
            </div>
            <div class="row button-block">
                <div class="col-sm-6">
                    <div class="online-discount-display">
                        <h3 style="font-size: 21px;" class="m-0">{{ resx.BookNow }} &amp;&nbsp;<span class="jk-gold">{{ resx.Save }} $<span
                                    class="online-discount-amount jk-gold">20</span>!</span> <span>*</span></h3>
                        <p style="font-size: 12px;" class="small m-0">* {{ resx.Excludesjobs }}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <button class="btn btn-success btn-block" id="btn-book-jk-truck" type="button" :disabled="truckCount == 0"
                        @click="goToSteep">
                        {{ resx.BookIt }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted,inject } from 'vue';

import pickup_truck_full from '@/assets/pickup-truck-full.png';
import pickup_truck_half from '@/assets/pickup-truck-half.png';
import junk_king_truck from '@/assets/junk-king-truck.png';

const resx = inject("resx");

const { emit } = getCurrentInstance();

const { jkLocation, zipCode, peRangeLow, peRangeHigh, jHPricing, fullPickupTruck, hallfPickupTruck } = defineProps({
    jkLocation: String,
    zipCode: String,
    peRangeLow: Number,
    peRangeHigh: Number,
    jHPricing: Array,
    fullPickupTruck: Number,
    hallfPickupTruck: Number
});

onMounted(() => {
    truckCount.value = halfTruckCount.value * 0.5 + fullTruckCount.value;
    updateTruckCount(truckCount.value);
    calcPercent();
});

const progressVal = ref('width: 0%;');

const percent = ref(0);
const truckCount = ref(0);
const fullTruckCount = ref(fullPickupTruck);
const halfTruckCount = ref(hallfPickupTruck);
const truckCountString = ref('');

const goToSteep = () => {
    emit('goToStep', 7);
}

const clearItems = () => {
    truckCount.value = 0;
    percent.value = 0;
    fullTruckCount.value = 0;
    halfTruckCount.value = 0;
    progressVal.value = 'width: 0%;';
    truckCountString.value = '';
    rangePrice.value = '$0';
    updateData();
}

const updateData = () => {
    updateRnagePrice();
    var items = Array();
    if (truckCount.value > 0) {
        items.push({ Count: truckCount.value, Name: resx.PickupTruckLoad });
    }
    emit('updatePickupTruckInfo', items, fullTruckCount.value, halfTruckCount.value, startingPrice.value, endingPrice.value, truckCountString.value);
}

const calcPercent = () => {
    updateTruckCount(truckCount.value);
    var calpercent = Math.round(truckCount.value * 100 / 6);
    percent.value = calpercent;
    progressVal.value = `width: ${percent.value}%;`
    updateData();
}

const updateTruckCount = (value) => {

    if (value == 1 || value == 2 || value == 3 || value == 4 || value == 5 || value == 6) {
        truckCountString.value = `${truckCount.value}.0`;
    }
    else {
        truckCountString.value = value;
    }
}

const addFull = () => {
    if (truckCount.value + 1 > 6) {
        emit('showModalError');
        return;
    }
    fullTruckCount.value += 1;
    truckCount.value += 1;

    calcPercent();

}
const removeFull = () => {
    if (fullTruckCount.value - 1 < 0) return;
    fullTruckCount.value -= 1;
    truckCount.value -= 1;
    calcPercent();
}

const addHalf = () => {
    if (truckCount.value + 0.5 > 6) {
        emit('showModalError');
        return;
    }
    halfTruckCount.value += 1;
    truckCount.value += 0.5;
    calcPercent();
}
const removeHalf = () => {
    if (halfTruckCount.value - 1 < 0) return;
    halfTruckCount.value -= 1;
    truckCount.value -= 0.5;
    calcPercent();
}


//calculate prising
const pricing = ref(jHPricing);

const startingPrice = ref(0);
const endingPrice = ref(0);
const rangePrice = ref('$0');
const updateRnagePrice = () => {
    calcPrice();
    if (startingPrice.value == 0 && endingPrice.value == 0) {
        rangePrice.value = '$0';
        return;
    }
    rangePrice.value = `$${startingPrice.value} - $${endingPrice.value}`;
}

const calcPrice = () => {

    var pIdx = -1;
    var calculatedVolume = truckCount.value / 6.0;
    if (calculatedVolume > 0) {
        if (calculatedVolume < .083) pIdx = 0;
        else if (calculatedVolume < .125) pIdx = 1;
        else pIdx = Math.floor((calculatedVolume * 12).toFixed(2)) + 1;
        var basePrice = pIdx >= pricing.value.length ? pricing.value[pricing.value.length - 1].EndingPrice : pricing.value[pIdx].StartingPrice;
        var foffset = calculatedVolume > .333 ? peRangeHigh : peRangeLow;
        startingPrice.value = Math.floor(foffset >= 0 ? basePrice : basePrice + foffset);
        endingPrice.value = Math.floor(foffset >= 0 ? basePrice + foffset : basePrice);
    } else {
        startingPrice.value = 0;
        endingPrice.value = 0;
    }
}
</script>
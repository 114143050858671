<template>
    <div class="modal-body step step-1" id="bf-choose-zipcode" style="display: block;">
        <div class="container-fluid">
            <h2>{{ isJw ? resx.WhatYourPostalCode : resx.WhatYourZipCode}}</h2>
            <div class="row">
                <div class="col-sm-4 col-3"></div>
                <div class="col-sm-4 col-6 p-0 d-flex justify-content-center">
                    <div class="input-group book-form">
                        <input v-model="zipCode" type="text" class="form-control" maxlength="7" autocomplete="disable"
                            @keypress.enter="searchZipCode">
                        <button @click="searchZipCode" class="btn green-button elevation-button" type="button">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
                <div class="col-sm-4 col-3"></div>
            </div>
        </div>

        <div v-if="showZipCodeError" id="zip-validation-msg">
            <h4>{{ resx.ValZipCode }}</h4>
        </div>

        <div v-if="zipValid" id="zip-validation-msg" class="mt-2">
            <h2>
                {{ resx.GoodNews }}
                <br>
                {{ resx.WeCovered }}
            </h2>
            <p style="font-size: 16px;" class="p-xs-5">{{ resx.S1Message }}</p>
            <h4>{{ jkLocation }}</h4><small class="additional-disclaimer"></small>
        </div>

        <div v-if="zipInvalid" id="zip-validation-msg" class="mt-2">
            <h2>{{ resx.Sorry }}.<br>{{ resx.AreaNotService }}</h2>
            <p style="font-size: 16px;">{{ resx.MistakeMessage }}<br>{{ resx.GiveCallMessage }}</p>
            <p style="font-size: 16px;" class="jk-phone-logo">
                <img :src="ico_jk_crown" class="img-crown" />
                &nbsp;1-888-888-Junk
            </p>
        </div>
    </div>
</template>
<script setup>
import { ref, getCurrentInstance, inject } from 'vue';
import { postAsync, logEvent } from "@/assets/api";


const resx = inject("resx");
const dnnConfig = inject("dnnConfig");

const { ico_jk_crown, isJw } = defineProps({
    ico_jk_crown: String,
    isJw: Boolean
});

const { emit } = getCurrentInstance();

const showZipCodeError = ref(false);
const zipValid = ref(false);
const zipInvalid = ref(false);
const zipCode = ref('');
const jkLocation = ref('');

const showZipValid = () => {
    showZipCodeError.value = false;
    zipInvalid.value = false;
    zipValid.value = true;
}

const showZipInvalid = () => {
    logEvent("BookingForm: ZipNotServiced", zipCode.value, dnnConfig);
    showZipCodeError.value = false;
    zipValid.value = false;
    zipInvalid.value = true;
}

const updatePrices = (prices) => {
    let values = prices.flatMap(item => [item.StartingPrice, item.EndingPrice])
    let minPrice = Math.min(...values);
    let maxPrice = Math.max(...values);
    emit('setPrices', minPrice, maxPrice);
}

    const searchZipCode = async (event) => {
        event.preventDefault();
    const zipCodeRegex = /^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]|[0-9]{5}$/;
    zipCode.value = zipCode.value.replace(/([a-z]\d[a-z])(\d[a-z]\d)/gi, '$1 $2');
    if (zipCode.value == '' || !zipCodeRegex.test(zipCode.value)) {
        console.log('zip failed-ms1');
        showZipCodeError.value = true;
        return;
    }
    console.log('zip passed-ms1');
    logEvent("BookingForm: Start", zipCode.value, dnnConfig);
    postAsync('PricingEstimator/GetPricing2', { Zip: zipCode.value }, dnnConfig)
        .then(async response => {
            if (response.Result.ServiceProviderId != null) {
                jkLocation.value = response.Result.ServiceProviderName;
                updatePrices(response.Result.DRPricing);
                showZipValid();
                await new Promise(resolve => setTimeout(resolve, 1000));
                emit('setZipCode', zipCode.value);
                emit('setJkLocation', jkLocation.value);
                emit('setZVResult', response.Result);
                console.log('GetPricing2 success-ms1');
                if (response.Result.DRPricing.length === 0) {
                    emit('setDTACLink', "/system/content/jk-dumpster-bag-insert.pdf");
                    emit('setshowSteep2', false);
                    emit('goToStep', 3);
                } else {
                    emit('setDTACLink', "/system/reports/report.aspx?id=dumpster-tc&sp=" + response.Result.ServiceProviderId + "&z=" + zipCode.value);
                    emit('setshowSteep2', true);
                    emit('goToStep', 2);
                }
            } else {
                showZipInvalid();
            }
        })
        .catch(() => {
            alert(resx.ServerError);
        });
}
</script>
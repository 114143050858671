<template>
    <div class="modal-body step step-9" id="confirmation-ccard">
        <div id="cc-entry-cont" v-if="showForm">
            <p style="font-size: 14px;">{{ resx.P9Info1 }}<span id="bf-nc-msg">{{ resx.P9Info2 }}</span><span id="bf-pa-msg"
                    style="display: inline;">{{ resx.P9Info3 }} $<span id="ccd-paa">{{
                        minPrice }}</span>, {{ resx.P9Info4 }}</span></p>
            <div class="row book-form px-1">
                <div class="col-sm-8 form-group g-1">

                    <input id="credit-card-num-tb" type="text" class="form-control cc-field integer-entry cc-entry input-lg"
                        maxlength="30" data-validate-as="cc-number" data-error="Please enter a valid credit card number"
                        :placeholder="resx.CardNumberPlaceholder"
                        :class="showValidates.Ccardno ? (formErrors.Ccardno ? 'frm-invalid' : 'frm-valid') : ''"
                        v-model="formModel.Ccardno" @blur="validateCcardno">
                    <span class="small text-white" v-if="formErrors.Ccardno">{{ formErrors.Ccardno }}</span>
                </div>
                <div class="col-sm-4 form-group g-1">
                    <input id="cc-expires-tb" type="text" class="form-control cc-expires input-lg"
                        :placeholder="resx.ExpDatePlaceholder" maxlength="5" data-validate-as="cc-expr"
                        data-error="Please enter a valid expiration date" autocomplete="off"
                        :class="showValidates.Expires ? (formErrors.Expires ? 'frm-invalid' : 'frm-valid') : ''"
                        v-model="formModel.Expires" @blur="validateExpires">
                    <span class="small text-white" v-if="formErrors.Expires">{{ formErrors.Expires }}</span>
                </div>
                <div class="col-sm-4 form-group g-1">
                    <input id="billing-zip-tb" type="text" class="form-control zip-entry input-lg"
                        :placeholder="resx.BillingZip" maxlength="10" data-validate-as="cc-bzip"
                        data-error="Please enter a valid billing zip/postal code"
                        :class="showValidates.Bzip ? (formErrors.Bzip ? 'frm-invalid' : 'frm-valid') : ''"
                        v-model="formModel.Bzip" @blur="validateBzip">
                    <span class="small text-white" v-if="formErrors.Bzip">{{ formErrors.Bzip }}</span>
                </div>
                <div class="col-sm-6 form-group g-1">
                    <input id="name-on-card-tb" type="text" class="form-control proper-case input-lg"
                        :placeholder="resx.NameCard" maxlength="30" data-validate-as="cc-noc"
                        data-error="Please enter name on card"
                        :class="showValidates.Noc ? (formErrors.Noc ? 'frm-invalid' : 'frm-valid') : ''"
                        v-model="formModel.Noc" @blur="validateNoc">
                    <span class="small text-white" v-if="formErrors.Noc">{{ formErrors.Noc }}</span>
                </div>
                <div id="cvnumber-fg" class="col-sm-2 form-group" style="display:none;">
                    <input id="cvnumber-tb" type="text" class="form-control integer-entry input-lg" placeholder="CVN"
                        maxlength="4">
                </div>
            </div>
            <div class="row button-block mt-1">
                <div class="col-1 col-sm-3 d-none d-sm-block"></div>
                <div class="col-12 col-sm-6 ">

                    <button class="btn btn-success btn-block" type="button" :disabled="isLoading" @click="saveCreditCard">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="isLoading"></span>
                        {{ resx.BookIt }}
                    </button>
                </div>
                <div class="col-1 col-sm-3 d-none d-sm-block"></div>
            </div>
            <div class="row" v-if="showError">
                <div class="col-sm-12">
                    <span style="font-size: 16px;" id="cc-result-span">{{ resx.CreditCardNotValidMessage }}</span>&nbsp;
                </div>
            </div>
        </div>
        <div id="cc-success-cont" v-if="showSuccess">
            <h2>{{ resx.YourAppointment }} <span class="jkno">{{ confNumber }}</span>.</h2>
            <p style="font-size: 16px;">{{ resx.P8Info }}</p>
            <div class="row button-block">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <button class="btn btn-success btn-block" type="button" data-bs-dismiss="modal" @click="closeModal()">
                        {{ resx.CloseWindow }}
                    </button>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="clr"></div>
    </div>
</template>
<script setup>
import { ref, inject, getCurrentInstance } from 'vue';
import { postAsync } from "@/assets/api";

const resx = inject("resx");
const dnnConfig = inject("dnnConfig");

const { emit } = getCurrentInstance();

const { minPrice, confNumber } = defineProps({
    minPrice: String,
    confNumber: String
});

const showError = ref(false);
const showSuccess = ref(false);
const isLoading = ref(false);
const showForm = ref(true);

const formModel = ref({
    Ccardno: '',
    Expires: '',
    Bzip: '',
    Noc: ''
});
const formErrors = ref({
    Ccardno: '',
    Expires: '',
    Bzip: '',
    Noc: ''
});
const showValidates = ref({
    Ccardno: false,
    Expires: false,
    Bzip: false,
    Noc: false
});


const saveCreditCard = async () => {

    if (validateForm()) {
        isLoading.value = true;
        initShowValidate();
        var match = formModel.value.Ccardno.match(/\d{4}$/g);
        var lastFour = match ? match[0] : '';
        var request = {
            Jkno: confNumber,
            Ccardno: formModel.value.Ccardno,
            Expires: formModel.value.Expires,
            Bzip: formModel.value.Bzip,
            Noc: formModel.value.Noc,
            Cvn: '',
            LastFour: lastFour
        };
        postAsync('BookOnline/SaveCreditCard', request, dnnConfig)
            .then(response => {
                isLoading.value = false;
                if (response.Result == 'Error') {
                    showForm.value = true;
                    showSuccess.value = false;
                    showError.value = true;
                } else {
                    emit('setShowBackBtn', false);
                    showForm.value = false;
                    showError.value = false;
                    showSuccess.value = true;
                }
            })
            .catch(() => {
                isLoading.value = false;
                alert(resx.ServerError);
            });
    }
}


//validations
function initShowValidate() {
    showValidates.value = {
        Ccardno: false,
        Expires: false,
        Bzip: false,
        Noc: false
    };
}
let validateForm = () => {
    validateCcardno();
    validateExpires();
    validateBzip();
    validateNoc();

    let valid = true;
    for (const key in formErrors.value) {
        if (formErrors.value[key] != '') {
            valid = false;
            break;
        }
    }
    return valid;
}

const validateCreditCardNumber = (number) => {
    let reversedDigits = number.split('').reverse().map(Number);
    let sum = 0;

    for (let i = 0; i < reversedDigits.length; i++) {
        let digit = reversedDigits[i];

        if (i % 2 === 1) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
    }
    return sum % 10 === 0;
};

const validateCcardno = () => {
    showValidates.value.Ccardno = true;
    if (formModel.value.Ccardno.length === 0) {
        formErrors.value.Ccardno = resx.ValCreditCardRequired;
    } else if (!validateCreditCardNumber(formModel.value.Ccardno)) {
        formErrors.value.Ccardno = resx.ValCreditCardInvalid;

    } else {
        formErrors.value.Ccardno = '';
    }
}

const validateExpires = () => {
    showValidates.value.Expires = true;
    formModel.value.Expires = formModel.value.Expires.trim();
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;

    if (formModel.value.Expires === '' || !regex.test(formModel.value.Expires)) {
        formErrors.value.Expires = resx.ValCardExpiresReq;
    } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear().toString().slice(-2);
        const currentMonth = currentDate.getMonth() + 1;

        const [expirationMonth, expirationYear] = formModel.value.Expires.split('/').map(Number);
        if (expirationYear < currentYear || (expirationYear === currentYear && expirationMonth < currentMonth)) {
            formErrors.value.Expires = resx.ValCardExpired;
        } else {
            formErrors.value.Expires = '';
        }
    }
}

const validateBzip = () => {
    showValidates.value.Bzip = true;
    formModel.value.Bzip = formModel.value.Bzip.trim();
    if (formModel.value.Bzip == '') {
        formErrors.value.Bzip = resx.ValBZipReq;
    } else {
        formErrors.value.Bzip = '';
    }
}

const validateNoc = () => {
    showValidates.value.Noc = true;
    formModel.value.Noc = formModel.value.Noc.trim();
    if (formModel.value.Noc == '') {
        formErrors.value.Noc = resx.ValNameOnCardReq;
    } else {
        formErrors.value.Noc = '';
    }
}

const closeModal = () => {
    emit('closeModal');
}
</script>
<template>
    <div style="text-align: center;" class="modal fade multi-step in" id="bfModal" data-bs-backdrop="static"
        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-center">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-1 d-flex justify-content-center">
                                <p>
                                    <button type="button" class="modal-btn-ico" @click="backSteep"
                                        v-if="currentSteep != 5">
                                        <span class="fa fa-angle-left"></span>
                                    </button>
                                </p>
                            </div>
                            <div class="col-10 text-center modal-link-title">
                                <p>
                                    <img :src="isJw ? ico_jw_crown : ico_jk_crown"
                                        style="margin-top: -8px; margin-right: 10px;" />
                                    <a :href="jkPhoneLink" rel="nofollow">{{ jkPhoneText }}</a>
                                </p>
                            </div>
                            <div class="col-1 text-end">
                                <p>
                                    <button type="button" data-bs-dismiss="modal" class="modal-btn-ico"
                                        @click="closeModal">
                                        <span class="fa fa-close"></span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- steep1 -->
                <ModalSteep1 v-if="currentSteep === 1" :ico_jk_crown="isJw ? ico_jw_crown : ico_jk_crown"
                    @setJkLocation="setJkLocation" @setZipCode="setZipCode" @goToStep="goToStep"
                    @setshowSteep2="setshowSteep2" @setPrices="setPrices" @setDTACLink="setDTACLink" @setZVResult="setZVResult" :isJw="isJw" />

                <!-- steep2 -->
                <ModalSteep2 v-if="currentSteep === 2" @goToStep="goToStep" @setIsDumpster="setIsDumpster"
                    :zipCode="zipCode" :jkLocation="jkLocation" :isJw="isJw" />

                <!-- steep3 -->
                <ModalSteep3 v-if="currentSteep === 3" @goToStep="goToStep" @setCustomerItems="setCustomerItems"
                    @setBfComments="setBfComments" :bfComments="bfComments" :jkLocation="jkLocation"
                    :customerItems="customerItems" :jkDiscount="jkDiscount" />

                <!-- steep4 -->
                <ModalSteep4 v-if="currentSteep === 4" @goToStep="goToStep" @setJkCoupon="setJkCoupon"
                    @setCustomerInfo="setCustomerInfo" @setConfNumber="setConfNumber"
                    @setJkCouponMessage="setJkCouponMessage" @setSelectTimes="setSelectTimes" :selectTimes="selectTimes"
                    :bfComments="bfComments" :customerItems="customerItems" :jkLocation="jkLocation" :zipCode="zipCode"
                    :isDumpster="isDumpster" :customerInfo="customerInfo" :jkCoupon="jkCoupon"
                    @setTimeText="setTimeText" :jkDiscount="jkDiscount" :zvres="zvres" />

                <!-- steep5 -->
                <ModalSteep5 v-if="currentSteep === 5" @goToStep="goToStep" @updateCardInfo="updateCardInfo"
                    :jkLocation="jkLocation" :zipCode="zipCode" :minPrice="minPrice" :cardInfo="cardInfo"
                    :acepTermsandConditions="acepTermsandConditions" :confNumber="confNumber"
                    :jkDiscount="jkDiscount" :dtacLink="dtacLink" />

                <!-- steep6 -->
                <ModalSteep6 v-if="currentSteep === 6" @closeModal="closeModal" :jkLocation="jkLocation"
                    :confNumber="confNumber" :dateTime="dateTime" :jkCoupon="jkCoupon"
                    :jkCouponMessage="jkCouponMessage" :customerItems="customerItems" :customerInfo="customerInfo"
                    :bfComments="bfComments" :isDumpster="isDumpster" :zipCode="zipCode" :timeText="timeText" />

                <!-- steep7 -->
                <ModalView7 v-if="currentSteep === 7" @goToStep="goToStep" :jkLocation="jkLocation" :zipCode="zipCode"
                    :minPrice="minPrice" :maxPrice="maxPrice" :dtacLink="dtacLink" />

                <div class="modal-footer mt-1">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ico_jk_crown from '@/assets/ico-jk-crown.svg';
import ico_jw_crown from '@/assets/ico-jw-crown.svg';
import { ref, getCurrentInstance } from 'vue';

const { emit } = getCurrentInstance();

const { jkPhoneText, jkPhoneLink, jkCoupon, jkDiscount, isJw } = defineProps({
    jkPhoneText: String,
    jkPhoneLink: String,
    jkCoupon: String,
    jkDiscount: String,
    isJw: Boolean
});


//components
import ModalSteep1 from './ModalSteep1.vue';
import ModalSteep2 from './ModalSteep2.vue';
import ModalSteep3 from './ModalSteep3.vue';
import ModalSteep4 from './ModalSteep4.vue';
import ModalSteep5 from './ModalSteep5.vue';
import ModalSteep6 from './ModalSteep6.vue';
import ModalView7 from './ModalView7.vue';



const minPrice = ref('');
const maxPrice = ref('');


const zipCode = ref('');
const confNumber = ref('');
const jkLocation = ref('');
const dtacLink = ref('');
const dateTime = ref('');
const jkCouponMessage = ref('');
const selectTimes = ref([]);
const timeText = ref('');
const customerInfo = ref({
    Name: '',
    Address: '',
    Phone: '',
    Email: '',
    Date: '',
    Time: ''
});

const cardInfo = ref({
    Ccardno: '',
    Expires: '',
    Bzip: '',
    Noc: ''
});
const acepTermsandConditions = ref(false);
const customerItems = ref([]);
const bfComments = ref('');

const zvres =  ref({ spid: '', gacid: '', gacl: '', msc: '', countryid: '' });

const resetModal = () => {
    zipCode.value = '';
    confNumber.value = '';
    jkLocation.value = '';
    dtacLink.value = '';
    dateTime.value = '';
    jkCouponMessage.value = '';
    customerItems.value = [];
    customerInfo.value = {
        Name: '',
        Address: '',
        Phone: '',
        Email: '',
        Date: '',
        Time: ''
    };
    bfComments.value = '';
    currentSteep.value = 1;
    previusSteep.value = 1;
    cardInfo.value = {
        Ccardno: '',
        Expires: '',
        Bzip: '',
        Noc: ''
    };
    acepTermsandConditions.value = false;
}

//NAVIGATION BEGIN
const showSteep2 = ref(false);
const isDumpster = ref(false);
const currentSteep = ref(1);
const previusSteep = ref(1);
const backSteep = () => {
    if (currentSteep.value > 1) {
        if (currentSteep.value === previusSteep.value) {
            if (currentSteep.value === 7) {
                currentSteep.value = 2;
                previusSteep.value = 2;

            } else if (currentSteep.value === 4 && isDumpster.value) {
                currentSteep.value = 7;
                previusSteep.value = 2;
            } else if (currentSteep.value == 3 && !showSteep2.value) {
                currentSteep.value = 1;
                previusSteep.value = 1;
            }
            else {
                currentSteep.value = currentSteep.value - 1;
                previusSteep.value = currentSteep.value;
            }
        } else {
            currentSteep.value = previusSteep.value;
        }
    }
}

const goToStep = (step) => {
    previusSteep.value = currentSteep.value;
    currentSteep.value = step;
}

const closeModal = () => {
    resetModal();
}
//NAVIGATION END

//setters
const setZipCode = (zip) => {
    zipCode.value = zip;
}
const setJkLocation = (location) => {
    jkLocation.value = location;
}
const setDTACLink = (link) => {
    dtacLink.value = link;    
}
const setCustomerItems = (items) => {
    customerItems.value = items;
}
const setBfComments = (comments) => {
    bfComments.value = comments;
}
const setIsDumpster = (isIt) => {
    isDumpster.value = isIt;
    if (isIt) {
        customerItems.value = [];
        bfComments.value = '';
    }
}
const setJkCoupon = (coupon) => {
    emit('setJkCoupon', coupon)
}
const setCustomerInfo = (info) => {
    customerInfo.value = info;
}
const setConfNumber = (conf) => {
    confNumber.value = conf;
}
const setJkCouponMessage = (message) => {
    jkCouponMessage.value = message;
}
const setSelectTimes = (times) => {
    selectTimes.value = times;
}
const setTimeText = (text) => {
    timeText.value = text;
}
const setshowSteep2 = (show) => {
    showSteep2.value = show;
}

const setPrices = (min, max) => {
    minPrice.value = min;
    maxPrice.value = max;
}

const updateCardInfo = (info, acept) => {
    cardInfo.value = info;
    acepTermsandConditions.value = acept;
}
const setZVResult = (resp) => {
    zvres.value.countryid = resp.CountryID;
    zvres.value.gacid = resp.GAConversionID;
    zvres.value.gacl = resp.GAConversionLabel;
    zvres.value.msc = resp.MSConversionTracking;
    zvres.value.spid = resp.ServiceProviderId;
}
</script>
<template>
 <div class="modal-body step step-7 service-selection" id="service-dumpster-info">
        <div class="container-fluid">
            <div class="row header-block">
                <div class="col-md-12">
                    <h4><span class="jk-location"><i class="fa fa-map-marker-alt"></i>
                            {{ jkLocation }}</span>&nbsp;(<span class="jk-zip-code">{{
                                zipCode }}</span>)</h4>
                    <h2 class="p-0 m-0 jk-gold">{{ resx.DumpsterRental }}</h2>
                    <h3>3-{{ resx.DayDumpster }}</h3>
                </div>
            </div>
        </div>
        <div class="row bg-light estimate-bar">
            <div class="col-12" style="text-align:center!important;">
                <h3>{{ resx.OnlyPay }}</h3>
                <div class="row" style="padding:0px 20px 0px 20px;">
                    <div class="col-6" style="text-align:center;">
                        <h4>{{resx.Minimum}}<br><span class="jk-red" id="dr-min-price">${{ minPrice }}</span></h4>
                    </div>
                    <div class="col-6" style="text-align:center;">
                        <h4>{{resx.Full}}<br><span class="jk-red" id="dr-full-price">${{ maxPrice }}</span></h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="col-12">
                <div style="font-size: 20px !important; padding: 10px 0px 0px 0px;">
                    <h5>{{ resx.UseCreditCard }}</h5>
                </div>
            </div>
        </div>

        <div class="row button-block">
            <div class="col-sm-3 col-1 d-none d-md-block"></div>
            <div class="col-sm-6 col-12">
                <button class="btn btn-success btn-block" id="btn-book-dr" type="button" @click="showModalTerm = true">
                    {{resx.ScheduleDrop}}
                </button>
            </div>
            <div class="col-sm-3 col-1 d-none d-md-block"></div>
        </div>

    </div>

    <div class="modal dialog in" role="dialog" tabindex="-1" id="tac-dialog" data-this-step="0" data-prev-step="0"
        style="display: block;" aria-hidden="false" v-if="showModalTerm">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">

                    <h3 class="text-center">{{ resx.ReviewTerms }}</h3>
                    <button style="font-size: 22px;" type="button" data-bs-dismiss="modal" class="modal-btn-ico" @click="showModalTerm = false">
                        <span class="fa fa-close"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <p style="font-size: 1rem;"> {{ resx.ModalTerms }}
                        <a class="dtac-link" target="_blank" :href="dtacLink">
                            {{ resx.Terms }}
                        </a> 
                        {{resx.ForMoreInfo}}
                    </p>
                </div>
                <div class="modal-footer pb-2 d-flex justify-content-center">
                    <button class="btn tac-agree mr-2" id="tacd-close" data-dismiss="modal" type="button"
                        @click="goToStep(4)">
                        {{ resx. Gotit }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showModalTerm"></div>
</template>
<script setup>
import { getCurrentInstance, ref, inject } from 'vue';

const resx = inject("resx");

const { jkLocation, zipCode, minPrice, maxPrice, dtacLink } = defineProps({
    jkLocation: String,
    zipCode: String,
    minPrice: Number,
    maxPrice: Number,
    dtacLink: String
});
const showModalTerm = ref(false);

const { emit } = getCurrentInstance();

const goToStep = (step) => {
    showModalTerm.value = false;
    emit('goToStep', step);
}
</script>
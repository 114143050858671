<template>
    <div class="modal-body step step-5" id="">
        <div class="row header-block">
            <div class="col-md-12">
                <h2>{{ resx.Great }}!<br>{{ resx.LetsGetSomeInfo }} &nbsp;&nbsp;</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-1 col-sm-3"></div>
            <div class="col-10 col-sm-6 book-form">
                <div class="form-group ">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderName"
                        :aria-label="resx.FormPlaceholderName" v-model="formModel.Name" @blur="validateName"
                        :class="showValidates.Name ? (formErrors.Name ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Name">{{ formErrors.Name }}</span>
                </div>
                <div class="form-group mt-1">
                    <input type="text" class="form-control" :placeholder="resx.FormPlaceholderEmail"
                        :aria-label="resx.FormPlaceholderEmail" v-model="formModel.Email" @blur="validateEmail"
                        :class="showValidates.Email ? (formErrors.Email ? 'frm-invalid' : 'frm-valid') : ''" />
                    <span class="small text-white" v-if="formErrors.Email">{{ formErrors.Email }}</span>
                </div>
            </div>
            <div class="col-1 col-sm-3"></div>
        </div>
        <div class="row mt-1">
            <div class="col-1 col-sm-3"></div>
            <div class="col-10 col-sm-6">
                <button class="btn btn-success btn-block" id="btn-start-ai" type="button" :disabled="isLoading"
                    @click="goToStep">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="isLoading"></span>
                    {{ resx.LetsGetStarted }}
                </button>
            </div>
            <div class="col-1 col-sm-3"></div>
        </div>

    </div>
    <div class="col-md-12 px-2">
        <p style="font-size: 14px;">{{ resx.P5Info }}</p>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, inject } from 'vue';
import { postAsync } from "@/assets/api";

const dnnConfig = inject("dnnConfig");
const resx = inject("resx");

const { zipCode, isTruck, contactInfo } = defineProps({
    zipCode: String,
    isTruck: Boolean,
    contactInfo: Object
});

const isLoading = ref(false);
const formModel = ref(contactInfo);
const formErrors = ref({
    Name: '',
    Email: ''
});
const showValidates = ref({
    Name: false,
    Email: false
});

const initShowValidate = () => {
    showValidates.value = {
        Name: false,
        Email: false
    }
}

const validateName = () => {
    showValidates.value.Name = true;
    const nameRegex = /^[A-Za-z]+ [A-Za-z]+$/;
    if (!nameRegex.test(formModel.value.Name)) {
        formErrors.value.Name = resx.ValName;
    } else {
        formErrors.value.Name = '';
    }
};

function validateEmail() {
    showValidates.value.Email = true;
    formModel.value.Email = formModel.value.Email.trim();
    if (formModel.value.Email == 'EMAIL' || formModel.value.Email == '') {
        formErrors.value.Email = resx.ValEmailReq;
    } else if (!formModel.value.Email.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)) {
        formErrors.value.Email = resx.ValEmailInvalid;
    } else {
        formErrors.value.Email = '';
    }
}

const validateForm = () => {
    validateName();
    validateEmail();

    let valid = true;
    for (const key in formErrors.value) {
        if (formErrors.value[key] != '') {
            valid = false;
            break;
        }
    }
    return valid;
}


const { emit } = getCurrentInstance();

const goToStep = async () => {
    if (validateForm()) {
        //saveContact
        isLoading.value = true;
        initShowValidate();
        postAsync('PricingEstimator/SaveContactInfo', { Name: formModel.value.Name, Email: formModel.value.Email, Zip: zipCode }, dnnConfig)
            .then(() => {
                isLoading.value = false;
                var step = isTruck ? 4 : 6
                emit("goToStep", step);
                emit('setContactInfo', formModel.value);
            })
            .catch(() => {
                isLoading.value = false;
                alert(resx.ServerError);
            });
    }
}
</script>
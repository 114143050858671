<template>
    <div style="text-align: center;" class="modal fade multi-step in" id="clModal" data-bs-backdrop="static"
        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-center">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-1">
                            </div>
                            <div class="col-10 text-center modal-link-title">
                                <p>
                                    <img :src="isJw ? ico_jw_crown : ico_jk_crown" style="margin-top: -8px; margin-right: 10px;" />
                                    <a href="tel:18888885865" rel="nofollow">(888) 888-JUNK</a>
                                </p>
                            </div>
                            <div class="col-1 text-end">
                                <p>
                                    <button type="button" data-bs-dismiss="modal" class="modal-btn-ico"
                                        @click="closeModal">
                                        <span class="fa fa-close"></span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body step step-1 pt-3 pb-5" id="bf-choose-zipcode" style="display: block;">
                    <div class="container-fluid">
                        <h2>  {{ isJw ? resx.WhatYourPostalCode : resx.WhatYourZipCode}}</h2>
                        <div class="row mt-2">
                            <div class="col-sm-4 col-3"></div>
                            <div class="col-sm-4 col-6 p-0 d-flex justify-content-center">
                                <div class="input-group book-form">
                                    <input v-model="zipCode" type="text" class="form-control" maxlength="7"
                                        autocomplete="disable" @keyup.enter="searchZipCode">
                                    <button @click="searchZipCode" class="btn green-button elevation-button"
                                        type="button">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-4 col-3"></div>
                        </div>
                    </div>
                    <div v-if="showZipCodeError" id="zip-validation-msg">
                        <h4>{{ resx.ValZipCode }}</h4>
                    </div>
                    <div v-if="zipValid" id="zip-validation-msg" class="mt-2">
                        <h2>
                            {{ resx.GoodNews }}
                            <br>
                            {{ resx.WeCovered }}
                        </h2>
                        <p style="font-size: 16px;" class="p-xs-5">{{ resx.S1Message }}</p>
                        <h4>{{ jkLocation }}</h4><small class="additional-disclaimer"></small>
                    </div>
                    <div v-if="zipInvalid" id="zip-validation-msg" class="mt-2">
                        <h2>{{ resx.Sorry }}.<br>{{ resx.AreaNotService }}</h2>
                        <p style="font-size: 16px;">{{ resx.ClModalError }}</p>
                        <div>
                            <button @click="goToOurLocations" type="button" class="btn green-button elevation-button">{{
                                resx.OurLocations }}</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer mt-1">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import ico_jk_crown from '@/assets/ico-jk-crown.svg';
    import ico_jw_crown from '@/assets/ico-jw-crown.svg';
    import { ref, inject } from 'vue';
    import { getAsync, writeCookie } from "@/assets/api";

    const { isJw } = defineProps({
    isJw: Boolean
});

    const resx = inject("resx");
    const dnnConfig = inject("dnnConfig");

    const showZipCodeError = ref(false);
    const zipInvalid = ref(false);
    const zipValid = ref(false);
    const zipCode = ref('');
    const jkLocation = ref('');

    const showZipValid = () => {
        showZipCodeError.value = false;
        zipInvalid.value = false;
        zipValid.value = true;
    }

    const showZipInvalid = () => {
        showZipCodeError.value = false;
        zipValid.value = false;
        zipInvalid.value = true;
    }

    const closeModal = () => {
        showZipCodeError.value = false;
        zipInvalid.value = false;
        zipValid.value = false;
        zipCode.value = '';
        jkLocation.value = '';
    }

    const searchZipCode = async () => {
        const zipCodeRegex = /^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]|[0-9]{5}$/;
        zipCode.value = zipCode.value.replace(/([a-z]\d[a-z])(\d[a-z]\d)/gi, '$1 $2');
        if (zipCode.value == '' || !zipCodeRegex.test(zipCode.value)) {
            showZipCodeError.value = true;
            return;
        }
        getAsync(`Site/GetServiceDataByZipCode?zipCode=${zipCode.value}`, dnnConfig)
            .then(async response => {
                if (response.Result) {
                    jkLocation.value = response.Result.Name;
                    showZipValid();
                    writeCookie('zipcode', zipCode.value, 90, dnnConfig);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    window.location.href = `locations/${response.Result.LocationUrl}`;
                } else {
                    showZipInvalid();
                }
            })
            .catch(() => {
                showZipInvalid();
            });
    }

    const goToOurLocations = () => {
        window.location.href = '/company/locations';
    }
</script>
<template>
  <div class="modal" tabindex="-1" id="modalCaution" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-white">
            {{ resx.Caution }}
          </h5>
          <button type="button" class="btn btn-m-close" @click="close">
            <i class="fa fa-close text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center text-black modal-link-title modal-message">
            {{ resx.CautionMessage }}
          </p>
          <div class="d-flex justify-content-center">
            <button class="btn btn-success leave-page mr-2" type="button" @click="Leave">{{ resx.LeavePage }}</button>
            <button class="btn btn-success ml-2" type="button" @click="close">{{ resx.Cancel }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModalCaution" class="modal-backdrop fade show" @click="close"></div>
</template>
<script setup>
import { getCurrentInstance, inject } from 'vue';
const { emit } = getCurrentInstance();
const resx = inject("resx");
const close = () => {
  emit('close');
}
const Leave = () => {
  emit('leaveSteep5');
}
const { showModalCaution } = defineProps({
  showModalCaution: Boolean
});
</script>
<template>
    <HomeView @setJkCoupon="setJkCoupon" :jkPhoneText="jkPhoneText" :jkPhoneLink="jkPhoneLink" :jkCoupon="jkCoupon"
        :jkDiscount="jkDiscount" :isJw="isJw" />
    <MainView :isJw="isJw" />
    <ModalView />
    <ChangeLocationModal :isJw="isJw" />
</template>
<script setup>
import HomeView from './PriceEstimator/HomeView.vue';
import MainView from './PriceEstimator2/MainView.vue';
import ModalView from './DumpsterBagModal/ModalView.vue';
import ChangeLocationModal from './ChangeLocation/ChangeLocationModal.vue'

import { ref, inject } from 'vue';
import { getPromoCode, getDiscount, formatPhoneNumber, isJunkWorks } from '@/assets/api';


const dnnConfig = inject("dnnConfig");

const jkPhoneText = ref('1-888-888-JUNK');
const jkPhoneLink = ref('tel:18888885665');

const jkCoupon = ref('');
const jkDiscount = ref('$20');

const updateValues = () => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentUrl).search);
    const pc = searchParams.get('pc');
    if (pc) {
        jkCoupon.value = getPromoCode(pc);
    }
    const s = searchParams.get('s');
    jkDiscount.value = getDiscount(s);

    const p = searchParams.get('p');
    if (p) {
        jkPhoneText.value = formatPhoneNumber(p);
        jkPhoneLink.value = `tel:1${p}`;
    }
}

const setJkCoupon = (coupon) => {
    jkCoupon.value = coupon;
}

updateValues();

const isJw = ref(false);

const updateIcon = async () => {
    var result = await isJunkWorks(dnnConfig);
    isJw.value = result;
}
updateIcon();
</script>